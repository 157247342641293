@use 'src/styles/variables' as variables;

.container {
	padding-top: var(--space-inset-2xs);
}

.gridItem {
	border: var(--disabled-color) solid 1px;
	border-radius: var(--border-radius-05);
	padding: var(--space-inset-xl);
}

.link.link.link {
	margin-right: 0;
	padding: 0;
	text-align: right;
}

.linkContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: var(--space-stack-xl);
}

/* LIST ITEM */

.priceColumn {
	display: flex;
	justify-content: space-between;
	font-weight: var(--font-weight-bold);
}

.linkColumn {
	width: 100%;
	text-align: right;
}

.productItemcontainer {
	border: var(--disabled-color) solid 1px;
	border-radius: var(--border-radius-05);
	padding: var(--space-inset-lg);
}

.listItemHeader {
	color: var(--text-heading-color);
	white-space: nowrap;

	div {
		display: inline-block;
	}
	div:last-child {
		text-align: right;
	}
}

@media (max-width: variables.$breakpoint-xl) {
	.priceColumn {
		flex-direction: column;
		align-items: flex-end;
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		padding: var(--space-stack-md);
	}
}

@media (max-width: variables.$breakpoint-sm) {
	.productItemcontainer {
		padding: var(--space-inset-md);
	}
	.priceColumn {
		flex-direction: column;
		align-items: flex-end;
	}
	.listItemHeader {
		white-space: normal;
	}
}

.priceContainer {
	width: 9rem;
	color: var(--text-heading-color);
	text-align: right;
}
