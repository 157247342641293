.input {
	border: 1px solid var(--text-light);
	border-radius: var(--border-radius-input);
	background-color: var(--bg-white);
	padding: 0.5rem 1rem;
	width: 100%;
	height: 3.5rem;
	color: var(--text-extra-dark-green);
}

.bordered {
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-input);
}

.container {
	display: flex;
	flex-direction: column;
}

.label {
	margin-bottom: 0.5rem;
}
