@use 'src/styles/variables' as variables;

.title {
	--title-color: var(--text-light);
	width: 100%;
}

.figure {
	margin-bottom: var(--space-stack-xs);
	width: 100%;
	color: var(--text-mint-green);
	font-weight: var(--font-weight-bold);
	font-size: 2.5rem;
}

.previousFigure {
	align-self: flex-end;
	width: 100%;
	min-height: 1.625rem;
	color: var(--text-light);
}

.icon {
	justify-self: center;
	margin-bottom: var(--space-stack-md);
}

.card {
	display: flex;
	flex-wrap: wrap;
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
}

@media (max-width: variables.$breakpoint-md) {
	.card {
		display: flex;
		justify-content: center;

		.container {
			display: flex;
			justify-content: center;
		}

		.title {
			text-align: center;
		}

		.figure {
			display: flex;
			justify-content: center;
		}

		.previousFigure {
			min-height: 1.625rem;
			text-align: center;
		}
	}
}

.operator {
	display: block;
	color: var(--primary-yellow);
	font-size: 4rem;
	line-height: 1;

	@media (min-width: variables.$breakpoint-md) {
		position: absolute;
		top: 5.25rem;
		right: -1rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: var(--space-stack-md);
		width: 100%;
		font-size: 5rem;
		line-height: 0.5;
		text-align: center;
	}
}
