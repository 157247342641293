.container {
	display: flex;
	position: relative;
	justify-content: center;
}

.size--md svg {
	width: 4rem;
	height: 4rem;
}

.size--lg svg {
	width: 7rem;
	height: 7rem;
}

.size--xl svg {
	width: 10rem;
	height: 10rem;
}

.size--xxl svg {
	width: 15rem;
	height: 15rem;
}
