@use 'src/styles/variables' as variables;

.container {
	position: relative;
}
.circle {
	display: flex;
	position: absolute;
	top: -8rem;
	right: 3rem;
	justify-content: center;
	align-items: center;
	border-radius: var(--border-radius-round);
	width: 12rem;
	height: 12rem;
	svg {
		position: absolute;
		top: 2rem;
	}
	@media (max-width: variables.$breakpoint-lg) {
		right: 2.1rem;
	}
}

.sticker {
	display: flex;
	position: relative;
	justify-content: center;
	border-radius: var(--border-radius-1);
	padding: var(--space-inset-md);
	width: 18.4rem;
	div:last-child {
		text-align: center;
	}
	div {
		z-index: 1;
	}

	@media (max-width: variables.$breakpoint-lg) {
		width: 16.5rem;
		div:last-child {
			font-size: var(--font-size-h2);
		}
	}
}
