@use 'src/styles/variables' as variables;

.reviewItem,
.modalReviewItem {
	display: flex;
	flex-direction: column;
	gap: var(--space-stack-sm);
}
.reviewItem {
	@media screen and (max-width: variables.$breakpoint-xl) {
		border-bottom: 1px solid var(--line-color);
		padding-bottom: var(--space-stack-lg);
	}
}

.review {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.companySize,
.reviewDate {
	color: var(--border-color);
}

.modal {
	width: 100%;
	max-width: 1200px;
}
