@use 'src/styles/variables' as variables;

.itemFilter {
	padding-bottom: var(--space-stack-md);
}
.checkboxContainer {
	display: flex;
	justify-content: space-between;
}

.label {
	font-weight: var(--font-weight-bold);
}

.allCheckbox {
	margin-bottom: var(--space-inset-sm);
	border-bottom: 1px var(--border-color-light) solid;
}

.box {
	display: inline-block;
	width: 24px;
	height: 24px;
}

.showMoreContainer {
	display: flex;
	margin-left: 22px;
}

.showMoreButton {
	cursor: pointer;
	padding-left: var(--space-inline-md);
	color: var(--text-primary-green);
	font-weight: var(--font-weight-bold);
}

.icon {
	transition: all 0.3s ease;
	margin-left: var(--space-inline-xs);
	&.iconOpen {
		transform: rotate(180deg);
	}
}
