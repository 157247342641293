@use 'src/styles/variables' as variables;
.cityFooter {
	text-align: center;

	.cities {
		margin: 0 auto;
		border-bottom: none;
		border-radius: var(--border-radius-05);
		text-align: left;

		@media (min-width: 1700px) {
			max-width: 100%;
		}

		.alphabetContainer {
			.alphabet {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				padding: var(--space-inset-lg) 0;
				color: var(--primary-green);

				@media (max-width: variables.$breakpoint-md) {
					padding: var(--space-inset-md) 0;
				}

				.link {
					margin-right: var(--space-inset-xl);
				}

				.char {
					opacity: 0.25;
					margin-right: var(--space-inset-xl);
				}
			}
		}
	}

	.gridContainer {
		margin: 0 auto;
		width: 50%;
		text-align: center;

		a {
			margin-right: 0;
		}
	}
}
