@use 'src/styles/variables' as variables;

.carousel {
	display: grid;
	position: relative;

	.carouselControls {
		display: flex;
		position: absolute;
		top: calc(50% - (var(--space-inset-xl) / 2));
		left: calc(-1 * var(--space-inset-md));
		justify-content: space-between;
		z-index: 1;
		width: calc(100% + var(--space-inset-xl));

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-radius: var(--border-radius-round);
			background-color: var(--bg-white);
			padding: var(--space-inset-2xs);
			width: var(--space-inset-xl);
			height: var(--space-inset-xl);
			pointer-events: all;

			svg {
				max-width: var(--space-inset-lg);
				max-height: var(--space-inset-lg);
			}
		}

		@media screen and (min-width: variables.$breakpoint-lg) {
			left: calc(-1 * var(--space-inset-xl));
			width: calc(100% + var(--space-inset-3xl));

			button {
				background-color: transparent;
				svg {
					max-width: var(--space-inset-xl);
					max-height: var(--space-inset-xl);
				}
			}
		}
	}

	.carouselInner {
		border-radius: var(--border-radius-05);
		height: 100%;
		overflow: hidden;

		.carouselItems {
			display: flex;
			position: relative;
			left: calc(-50% - var(--space-inset-md));
			column-gap: var(--space-inset-xl);
			flex-direction: row;
			height: 100%;

			&.single {
				left: calc(-100% - var(--space-inset-xl));
				min-width: 0;
			}
			@media screen and (max-width: variables.$breakpoint-md) {
				left: calc(-100% - var(--space-inset-xl));
				min-width: 0;
			}

			.carouselItem {
				position: relative;
				flex: 1 0 calc(50% - var(--space-inset-md));

				&.single {
					flex: 1 0 100%;
					width: 100%;
				}

				&.animateLeft {
					animation: animateLeft 0.5s;
				}

				&.animateRight {
					animation: animateRight 0.5s;
				}

				@media screen and (max-width: variables.$breakpoint-md) {
					flex: 1 0 100%;
				}
			}
		}

		.carouselDots {
			display: flex;
			position: absolute;
			bottom: calc(-1 * var(--space-inset-md));
			justify-content: center;
			z-index: 2;
			margin-top: var(--space-inset-md);
			width: 100%;

			.dot {
				cursor: pointer;
				margin-right: var(--space-inset-xs);
				border-radius: var(--border-radius-round);
				background-color: var(--line-color);
				width: var(--space-inset-xs);
				height: var(--space-inset-xs);

				&.active {
					background-color: var(--primary-dark-green);
				}
			}
		}
	}
}

@keyframes animateLeft {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-100% - var(--space-inset-xl)));
	}
}
@keyframes animateRight {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(100% + var(--space-inset-xl)));
	}
}

.transition {
	transition: transform 0.5s ease;
}

.itemOrder {
	order: 0;
}
