.radiobutton {
	display: flex;
	align-items: center;
	margin-bottom: var(--space-stack-xs);

	.outerContainer {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding-bottom: var(--space-stack-xs);
	}

	.outerContainer:last-child {
		padding-bottom: 0;
	}

	.outerContainer:not(.disabled):hover .checkContainer {
		border-color: var(--border-hovered-color);
	}

	.bordered {
		border: solid 1px transparent;
		border-radius: var(--border-radius-input);
		background: var(--bg-white);
		padding: var(--space-inset-lg);
	}

	.checkContainer {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease-in-out;
		margin-right: var(--space-inline-md);
		border: 1px solid var(--border-color);
		border-radius: var(--border-radius-round);
		background: var(--bg-white);
		padding: var(--space-inset-2xs);
		width: 24px;
		height: 24px;
	}

	.checkDot {
		opacity: 0;
		transition: all 0.3s ease-in-out;
		border-radius: var(--border-radius-round);
		background-color: var(--disabled-color);
		width: 100%;
		height: 100%;
	}

	.horizontal {
		margin-right: var(--space-inline-xl);
	}

	.horizontal:last-child {
		margin-right: 0;
		padding-bottom: var(--space-stack-xs);
	}

	.input {
		clip: rect(0 0 0 0);
		position: absolute;
		clip-path: inset(50%);
		width: 1px;
		height: 1px;
		overflow: hidden;
		white-space: nowrap;
	}

	.input:focus + label {
		outline: -webkit-focus-ring-color auto 1px;
		outline-style: auto;
	}

	.input:focus:not(:focus-visible) + label {
		outline: none;
	}

	.input:checked + .outerContainer:not(.disabled) .checkContainer {
		border-color: var(--action-color);
	}

	.input:checked + .outerContainer:not(.disabled) .checkDot {
		opacity: 1;
		transition: all 0.3s ease-in-out;
		background-color: var(--action-color);
	}

	.input:checked + .outerContainer:hover:not(.disabled) .checkContainer {
		border-color: var(--action-hovered-color);
	}

	.input:checked + .outerContainer:hover:not(.disabled) .checkDot {
		opacity: 1;
		background-color: var(--action-hovered-color);
	}

	.input:checked + .outerContainer.disabled .checkContainer {
		border-color: var(--disabled-color);
	}

	.input:checked + .outerContainer.disabled .checkDot {
		opacity: 1;
		background-color: var(--disabled-color);
	}

	.tooltipWrapper {
		display: flex;
		align-items: center;
		margin-left: var(--space-inline-sm);
		padding-bottom: var(--space-stack-sm);
	}
}
