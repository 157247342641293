@use 'src/styles/variables' as variables;

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--line-color);
	border-radius: var(--border-radius-05);
	padding: var(--space-inset-md) var(--space-inset-lg) var(--space-inset-md) var(--space-inset-md);

	@media (max-width: variables.$breakpoint-md) {
		flex-direction: column;
		align-items: flex-start;
		gap: var(--space-stack-lg);
	}
}

.infoRow {
	display: flex;
	align-items: center;
	gap: var(--space-inline-xl);
}

.btn.btn.btn {
	margin-right: 0;
}
