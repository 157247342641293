.contactInformation {
	background-color: var(--bg-beige-light);
}

.contactMethod {
	display: flex;
	align-items: center;
	gap: var(--space-inset-md);
	height: var(--space-inset-xl);
}

.showAccessRights {
	margin-top: var(--space-stack-lg);
}
