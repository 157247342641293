@use 'src/styles/variables' as variables;

.card {
	display: flex;
	flex-direction: column;
	z-index: var(--z-index-1);

	&.padding {
		padding: var(--space-inset-3xl) var(--space-inset-xl);

		@media (max-width: variables.$breakpoint-xl) {
			padding: var(--space-inset-xl);
		}
	}

	&.opacity {
		opacity: 0.9;
	}
}

.iconWrapper {
	display: flex;
	justify-content: center;
	margin-bottom: var(--space-stack-lg);
}

.btnWrapper {
	display: flex;
	flex: 1;
	align-items: flex-end;
	gap: 2rem;
}

.btnCenter {
	justify-content: center;
}
