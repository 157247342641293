@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.titleWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	margin-top: 2rem;
}

.subMenuMobile {
	display: none;

	@media (pointer: coarse), (max-width: variables.$breakpoint-lg) {
		display: flex;
	}
}

.titleWrapper,
.subMenuDesktop.subMenuDesktop {
	@media (pointer: coarse), (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}
