.tagContainer {
	display: flex;
	flex-wrap: wrap;

	&.marginBottom {
		margin-bottom: var(--space-stack-md);
	}
}

.tag {
	font-weight: var(--font-weight-bold);
}

.verticalSeparator {
	padding: 0 var(--space-inset-xs);
}
