@use 'src/styles/animations' as animations;

$icon-size: 1rem;
$tip-width: 6px;

.container {
	position: relative;
	height: $icon-size;
}

.hidden {
	display: none;
}

.icon {
	z-index: var(--z-index-2);
	cursor: pointer;
	width: $icon-size;
	height: $icon-size;
}

.tooltip {
	position: absolute;
	z-index: var(--z-index-3);
	animation: fadeIn 0.5s;
	box-shadow:
		0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	border-radius: var(--border-radius-05);
	padding: var(--space-inset-md);
	width: max-content;
	min-width: 12rem;
}

.theme--white {
	&.position--right::after {
		border-right-color: var(--bg-white);
	}

	&.position--left::after {
		border-left-color: var(--bg-white);
	}
}

.theme--darkGreen {
	&.position--right::after {
		border-right-color: var(--bg-dark-green-full);
	}

	&.position--left::after {
		border-left-color: var(--bg-dark-green-full);
	}
}

.position--left,
.position--right {
	top: 50%;
	transform: translateY(-50%);
}

.position--right {
	left: calc($icon-size + $tip-width);
	margin-left: 0.3rem;
}

.position--left {
	right: calc($icon-size + $tip-width);
	margin-right: 0.3rem;
}

.position--left::after,
.position--right::after {
	position: absolute;
	top: calc(50% - ($tip-width / 2));
	top: 50%;
	margin-top: calc(-1 * var(--space-stack-2xs));
	border-width: $tip-width;
	border-style: solid;
	border-color: transparent;
	content: ' ';
}

.position--right::after {
	right: 100%;
}

.position--left::after {
	left: 100%;
}
