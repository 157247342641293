@use 'src/styles/variables' as variables;

.chartContainer {
	margin-bottom: var(--space-inline-xl);
	text-align: center;
}

.amount {
	font-size: 1.5rem;
}

.chart {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	div:first-child {
		width: 15rem;

		@media (max-width: variables.$breakpoint-lg) {
			width: 10rem;
		}
	}
}

.vacationContainer {
	margin-bottom: var(--space-stack-xl);
	border-radius: var(--border-radius-05);
	background-color: var(--bg-white);
	padding: var(--space-inset-md);
}

.incrementContainer {
	display: flex;
	justify-content: space-between;
	padding: var(--space-stack-sm);

	.incrementerText {
		margin-left: var(--space-inline-2xs);
	}
}

.datePicker {
	margin-bottom: var(--space-inline-xl);
}

.costContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--space-stack-md);
	font-weight: var(--font-weight-bold);
	line-height: initial;
	white-space: nowrap;
}

.monthlyCost {
	font-size: var(--font-size-h1);
}

.curreny {
	font-size: var(--font-size-h3);
}
