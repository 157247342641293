.list {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
	list-style-type: none;

	li {
		padding-left: 1.3em;
		list-style: none;
		list-style-type: none;
	}

	li a {
		font-weight: var(--font-weight-light);
	}

	li::before {
		float: left;
		margin-left: -1.3em;
		width: 1.3em;
		content: '';
		color: var(--icon-color);
		/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
		font-family: 'Font Awesome 6 Pro';
	}
}
