.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: var(--text-heading-color);
}

.icon {
	margin: 1rem 0;
}

.divider {
	width: 10rem;
}
