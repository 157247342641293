.tags {
	background-color: var(--bg-beige-light);

	.tagsList {
		display: flex;
		flex-wrap: wrap;
		gap: var(--space-inset-sm);
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
