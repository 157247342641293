.body {
	margin-bottom: var(--space-stack-3xl);
}

.container {
	column-gap: 8rem;
}

.cardContainer {
	position: sticky;
	top: calc(var(--global-menu-height) + 2rem);
	@media (max-width: 61.5rem) {
		position: initial;
		margin-top: var(--space-stack-xl);
	}
}

.infoCard {
	margin-bottom: var(--space-stack-md);
	border-radius: var(--border-radius-2);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-xl);
}

.infoBtn {
	margin-top: var(--space-stack-md);
	width: 100%;
}

.iconContainer {
	display: flex;
	flex-direction: row;
}

.mailIcon {
	display: flex;
	align-self: flex-end;
}

.iconBtn {
	margin-right: var(--space-stack-md);
	height: 1.5rem;
	font-size: 1.5rem;
}
