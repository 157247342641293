@use 'src/styles/functions' as functions;
@use 'src/styles/animations' as animations;
@use 'src/styles/variables' as variables;

.backdrop {
	position: fixed;
	z-index: var(--z-index-10);
	animation: fadeIn 0.8s;
	inset: 0;
	background-color: var(--black-30-alpha);
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.hidden {
	display: none;
}

.menu {
	position: fixed;
	top: 0;
	left: 0;
	align-items: flex-start;
	z-index: var(--z-index-max);
	animation: fadeIn 0.8s;
	margin: 0 auto;
	background-color: var(--bg-dark-green-full);
	max-width: var(--global-max-width);
	height: 100vh;

	&.visible {
		left: 0;
		@media (max-width: variables.$breakpoint-md) {
			width: 100%;
		}
	}

	.linkWrapper {
		margin-bottom: 0.25rem;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--space-inset-lg);
	width: 100%;
	height: var(--global-menu-height);

	.logoContainer {
		display: flex;
		align-items: center;
	}

	.logo {
		display: flex;
		position: relative;
		float: left;
		width: 8.625rem;
		@media (max-width: variables.$breakpoint-md) {
			width: 6.25rem;
		}
	}

	.closeMenuButton {
		float: left;
		cursor: pointer;
		margin-right: var(--space-inset-lg);
		border: 0;
		background: transparent;
		padding: 0;
		min-width: 20px;
	}

	.closeButton {
		justify-self: flex-end;
		float: right;
		border: 0;
		background: 0;
		color: white;
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-md);
		height: 4.25rem;
		.closeMenuButton {
			margin-right: var(--space-inset-md);
		}
	}
}

.panelContainer {
	display: flex;
	position: relative;

	.brandSymbol {
		position: absolute;
		right: var(--space-inset-xl);
		bottom: var(--space-stack-lg);

		@media (max-width: variables.$breakpoint-md) {
			display: none;
		}
	}

	.firstPanel {
		position: relative;
		width: var(--global-menu-panel-width);
		max-width: 100%;

		@media (max-width: variables.$breakpoint-md) {
			max-width: 100vw;
		}

		.footer {
			display: flex;
			position: absolute;
			bottom: 0;
			left: 0;
			align-items: center;
			background: var(--bg-dark-green-full);
			padding: 0 var(--space-inset-lg);
			width: var(--global-menu-panel-width);
			max-width: 100%;
			height: auto;
			height: 5rem;

			.footerLinks {
				display: flex;
				justify-content: space-between;
				margin: 0;
				padding: 0;
				width: 100%;
				list-style-type: none;
			}

			@media (max-width: variables.$breakpoint-md) {
				z-index: calc(var(--z-index-10) + 1);
				padding: 0 var(--space-inset-md);
				max-width: 100vw;
				height: 4rem;
			}
		}

		.subList {
			margin: 0;
			padding: 0 var(--space-inset-lg);
			padding-top: var(--space-stack-md);
			list-style-type: none;
		}
	}

	.secondPanel {
		position: relative;
		background: var(--bg-dark-green-full);
		width: var(--global-menu-panel-width);
		max-width: 100%;

		.header {
			display: flex;
			justify-content: flex-end;
		}

		.backButton {
			margin-bottom: var(--space-stack-xl);
			border: 0;
			background: 0;
			padding: 0;
			color: white;
		}

		.secondPanelList {
			margin: 0;
			padding: 0;
			overflow: hidden;
			list-style-type: none;
		}

		.addBorder {
			border-left: 8px solid var(--text-yellow);
		}

		.secondPanelHeaderLink {
			display: flex;
			margin-bottom: var(--space-stack-lg);
		}

		.secondPanelHeader {
			font-size: var(--font-size-h5);
		}

		@media (min-width: variables.$breakpoint-md) {
			animation: fadeIn 0.8s;
		}

		@media (max-width: variables.$breakpoint-md) {
			position: fixed;
			left: 0;
			z-index: var(--z-index-10);
			transition: left 0.4s;
			background: var(--sidenav-green);
			&.hidden {
				display: block;
				left: -480px;
			}
		}
	}
}

.groupContainer {
	padding: 0 var(--space-inset-lg);
	padding-top: var(--space-stack-lg);
	padding-bottom: var(--space-stack-3xl);
	height: calc(100vh - 5rem);
	overflow: auto;
	scrollbar-color: var(--text-yellow) #e6ecea;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e6ecea;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--text-yellow);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--text-yellow);
	}

	.linkGroup {
		margin: 0;
		margin-bottom: var(--space-stack-xl);
		padding: 0;
		list-style-type: none;
	}

	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}

	.linkGroup:nth-of-type(1),
	.linkGroup:nth-of-type(2) {
		li a {
			font-size: var(--font-size-h5);
		}
	}

	.linkWrapper {
		position: relative;
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: 0 var(--space-inset-md);
		padding-top: var(--space-stack-lg);
		padding-bottom: var(--space-stack-3xl);
		max-width: 100vw;
		height: calc(100vh - 4rem);
	}
}
