.article {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	padding: var(--space-inset-md);
}

.textWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
}

.image {
	position: relative;
	width: 6.25rem;
	min-height: 6.25rem;
	max-height: 6.25rem;
}

.image,
.image img {
	border-radius: var(--border-radius-05);
}
