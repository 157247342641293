.growthSupportContainer {
	margin-bottom: var(--space-inline-md);
	border-radius: var(--border-radius-05);
	background-color: var(--bg-white);
}

.radiobuttons {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding: var(--space-inset-md);
	width: 60%;

	.radiobutton {
		margin-bottom: 0;
	}
}
