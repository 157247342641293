@use 'src/styles/animations' as animations;

.container {
	display: flex;
	position: fixed;
	top: calc(1rem + var(--global-menu-height) + var(--global-menu-offset));
	right: 1rem;
	left: 1rem;
	justify-content: space-between;
	align-items: center;
	z-index: var(--z-index-8);
	margin: 0 auto;
	border-radius: var(--border-radius-2);
	padding: var(--space-inset-sm) var(--space-inset-xl);
	max-width: var(--global-max-width);

	&.open {
		opacity: 1;
		animation: fadeIn 0.3s ease;
	}

	&.close {
		opacity: 0;
		animation: fadeOut 0.3s ease;
	}
}

.content {
	display: flex;
	align-items: center;
	gap: var(--space-inset-md);
}

.icon {
	width: 1.5rem;
	min-width: 1.5rem;
	height: 1.5rem;
}
