@use 'src/styles/variables' as variables;

.calculator {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: var(--border-radius-05) 0 0 var(--border-radius-05);
	background: var(--bg-blue-light);
	padding: var(--space-inline-md);
	@media (max-width: variables.$breakpoint-lg) {
		border-radius: var(--border-radius-05) var(--border-radius-05) 0 0;
	}
}

.toolTip.toolTip.toolTip {
	min-width: 0;
	max-width: 14rem;
}

.description {
	border-radius: 0 var(--border-radius-05) var(--border-radius-05) 0;
	background: var(--selected-color);
	padding: var(--space-inline-md);
	width: 22.5rem;
	height: 100%;
	@media (max-width: variables.$breakpoint-lg) {
		border-radius: 0 0 var(--border-radius-05) var(--border-radius-05);
		width: auto;
	}
}

.formContentContainer {
	display: flex;
	height: 100%;
}

.formContainer {
	flex-grow: 1;
}

.buttonsContainer {
	margin-top: auto;
}

.calculationHeader {
	h3 {
		margin-top: 0;
	}
}

.form {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.coverageRateChart {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div:first-child {
		width: 15rem;

		@media (max-width: variables.$breakpoint-lg) {
			width: 10rem;
		}
	}
}

.coverageContribution {
	padding-bottom: var(--space-inline-3xl);
	text-align: center;
}

.headingRow {
	display: flex;
	align-items: center;
	margin-bottom: var(--space-stack-xl);
}

.coverageRateHeading {
	@media (max-width: variables.$breakpoint-sm) {
		width: 20rem;
	}
}

.heading {
	margin-left: var(--space-inline-md);
}

.selectField {
	margin-bottom: var(--space-stack-md);
}

.calculateButton {
	width: 100%;
}

.clearFieldsBtnContainer {
	margin-top: var(--space-stack-md);
	width: 100%;
	text-align: center;

	& :hover {
		opacity: 0.7;
	}
}
.clearFieldsButton {
	border: 0;
	background: 0;
	color: var(--text-primary-green);
	font-weight: var(--font-weight-bold);
	font-size: 18px;
}

.textFieldHeading {
	display: flex;
	margin-top: var(--space-inline-md);
	color: var(--text-heading-color);

	div:nth-child(2) {
		margin-left: var(--space-inline-md);
		@media (max-width: variables.$breakpoint-md) {
			display: none;
		}
	}
}
.percent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--space-stack-md);
	margin-bottom: var(--space-stack-2xl);
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-h1);
	white-space: nowrap;
}

.chart {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	div:first-child {
		width: 15rem;

		@media (max-width: variables.$breakpoint-lg) {
			width: 10rem;
		}
	}
}

.divider {
	margin-bottom: var(--space-stack-xl);
}

.numberInputFields input::-webkit-outer-spin-button,
.numberInputFields input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.numberInputFields input[type='number'] {
	-moz-appearance: textfield;
}

.resultRow {
	display: flex;
	margin-top: var(--space-stack-xl);
	padding: 0 0 0 var(--space-inline-2xl);

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: var(--space-stack-2xs);
	}

	@media (max-width: variables.$breakpoint-sm) {
		padding: 0;
	}
}

.resultAmount.resultAmount {
	font-weight: var(--font-weight-light);
}

.boldResultAmount.boldResultAmount {
	font-weight: var(--font-weight-bold);
}

.resultText {
	white-space: nowrap;
}

.line {
	margin-right: var(--space-inline-xl);
	border-radius: 6px;
	width: 7px;
}

.bigResult {
	@media (min-width: variables.$breakpoint-lg) {
		margin-right: var(--space-inline-2xl);
		margin-left: calc(var(--space-inline-xl) * -1);
		width: 12px;
	}
}

.pink {
	background: #fe85b5;
}

.purple {
	background: #9c54b9;
}

.yellow {
	background: #fec400;
}

.blue {
	background: #3785b7;
}

.lightpurple {
	background: #d9c1e8;
}

.grey {
	background: #bfc6c4;
}
