@use 'src/styles/variables' as variables;

.container {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: var(--global-max-width);

	&.borderRadius {
		@media (min-width: variables.$breakpoint-lg) {
			border-radius: var(--border-radius-2);
		}
	}
}

.margin-bottom--xl {
	margin-bottom: var(--space-stack-xl);
}

.margin-bottom--3xl {
	margin-bottom: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		margin-bottom: var(--space-stack-xl);
	}
}

.margin-bottom--6xl {
	margin-bottom: calc(var(--space-stack-3xl) * 2); // 8rem
}

.theme--dark-green-gradient {
	background: var(--bg-dark-green-full);
	background: linear-gradient(
		62deg,
		rgba(0, 56, 36, 1) 0%,
		rgba(3, 77, 51, 1) 30%,
		rgba(0, 56, 36, 1) 60%,
		rgba(0, 56, 36, 1) 100%
	);
}

.noBackgroundColor {
	background: none;
}
