@use 'src/styles/variables' as variables;
@use 'src/styles/functions' as functions;

.comparisionTableContainer {
	margin: 0 auto;
	max-width: functions.rem(1664px);

	.segmentRows {
		.segmentRow:last-child {
			.row:last-child {
				.col {
					border-bottom-right-radius: var(--border-radius-05);
					border-bottom-left-radius: var(--border-radius-05);
				}
			}
		}
	}

	.row {
		display: grid;
		grid-auto-columns: 1fr;
		grid-auto-flow: column;
		gap: 0.5rem;
		z-index: 10;

		@media (max-width: variables.$breakpoint-md) {
			grid-auto-columns: 1fr;
			grid-auto-flow: row;
		}

		.headerCol {
			@media (min-width: variables.$breakpoint-md) {
				border-radius: var(--border-radius-05) 0 0 var(--border-radius-05);
				background: var(--bg-light-grey);
			}
		}

		.sectionRowColumn.sectionRowColumn {
			min-height: 2.5rem;
		}

		.spanTwoColumnsOnDevice {
			@media (max-width: variables.$breakpoint-md) {
				grid-column: 1 / span 2;
			}
		}

		.spanThreeColumnsOnDevice {
			@media (max-width: variables.$breakpoint-md) {
				grid-column: 1 / span 3;
			}
		}

		.spanFourColumnsOnDevice {
			@media (max-width: variables.$breakpoint-md) {
				grid-column: 1 / span 4;
			}
		}

		.col {
			align-content: center;
			min-height: 4.375rem;

			@media (max-width: variables.$breakpoint-md) {
				min-height: 2.375rem;
			}

			&.addPositionRelative {
				position: relative;
			}

			.rowHeader {
				margin: 0.5rem 1rem;
				word-break: break-word;

				@media (max-width: variables.$breakpoint-md) {
					padding: var(--space-inset-md) 0;
				}
			}

			.rowDescription {
				align-content: center;
				margin-right: 0.5rem;
				padding-top: var(--space-inset-md);
				padding-bottom: var(--space-inset-md);
				height: 100%;
				word-break: break-word;
				@media (max-width: variables.$breakpoint-md) {
					padding-top: unset;
					padding-bottom: unset;
					height: unset;
				}
			}

			.content {
				align-content: center;
				margin-right: 0.5rem;
				margin-left: 0.5rem;
				padding-top: var(--space-inset-md);
				padding-bottom: var(--space-inset-md);
				height: 100%;
				word-break: break-word;

				.icons {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-top: 0.2rem;
					height: 100%;
				}

				@media (max-width: variables.$breakpoint-md) {
					padding-top: unset;
					padding-bottom: unset;
					height: unset;
				}
			}
		}
	}

	.section:last-child {
		.row:last-child {
			.col {
				border-radius: 0 0 var(--border-radius-05) var(--border-radius-05);
			}
		}
	}

	.primary {
		border-radius: var(--border-radius-05) var(--border-radius-05) 0 0;
	}

	.secondary {
		border-radius: var(--border-radius-05) var(--border-radius-05) 0 0;
	}

	.stickyHeader {
		position: sticky;
		top: 4.5rem;
		grid-auto-flow: column;
		z-index: 2;
		transition: background-color 1s ease;
		background-color: var(--bg-white);

		.col:first-child {
			@media (max-width: variables.$breakpoint-md) {
				display: none;
			}
		}

		@media (max-width: variables.$breakpoint-md) {
			top: 3.875rem;
		}
	}

	.stickyHeader.withInfoBannerActive {
		top: 8.5rem;

		@media (max-width: variables.$breakpoint-md) {
			top: 7.875rem;
		}
	}

	.stickyRow {
		position: sticky;
		top: 13.875rem;
		z-index: 1;
		border-radius: var(--border-radius-05);

		@media (max-width: variables.$breakpoint-lg) {
			top: 11.5rem;
		}

		@media (max-width: variables.$breakpoint-md) {
			top: 6.25rem;
			border-bottom: 1px solid var(--line-color);
			border-radius: unset;
			background-color: var(--bg-white);
		}

		div:not(:first-child) {
			@media (max-width: variables.$breakpoint-md) {
				display: none;
			}
		}
	}

	.stickyRow.withInfoBannerActive {
		top: 18.25rem;

		@media (max-width: variables.$breakpoint-xl) {
			top: 17.875rem;
		}

		@media (max-width: variables.$breakpoint-lg) {
			top: 15.5rem;
		}

		@media (max-width: variables.$breakpoint-md) {
			top: 10.25rem;
		}
	}

	&:not(.menuVersionV3) {
		.stickyProducts {
			border-radius: 0 !important;
		}
	}

	&.menuVersionV3 {
		.stickyHeader {
			top: 4.25rem;
		}
	}

	.stickyProducts {
		transition: color 1s ease;
		margin-top: var(--space-inset-2xl);

		@media (max-width: variables.$breakpoint-md) {
			margin-top: var(--space-inset-xl);
			padding-bottom: unset;
			height: auto;
		}
		.headerAndPriceBox.headerAndPriceBox {
			gap: 0.1rem;
			padding: var(--space-inset-xs) 0;
		}
	}

	.whiteRow.whiteRow {
		border: 1px solid var(--bg-green-light);
		border-radius: var(--border-radius-05);
		background-color: var(--bg-white);
	}

	.textAlignCenter {
		font-weight: var(--font-weight-light);
		text-align: center;
	}

	.headerAndPriceBox {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		padding: var(--space-inset-md) 0;
		height: 100%;
		min-height: 6.25rem;

		@media (max-width: variables.$breakpoint-md) {
			padding: var(--space-inset-sm) 0;
			min-height: auto;
		}
	}

	.productHeader {
		font-weight: var(--font-weight-bold);
		font-size: var(--font-size-h4);
		hyphens: auto;
		&.stickyHeaderText {
			margin-top: 0;
		}

		@media (max-width: variables.$breakpoint-xl) {
			font-size: var(--font-size-h5);
		}

		@media (max-width: variables.$breakpoint-md) {
			font-size: var(--font-size-h6);
		}

		@media (min-width: variables.$breakpoint-lg) {
			font-size: 1.8rem;
		}
	}

	.borderBottom {
		border-bottom: 1px solid var(--line-color);
		&.borderBottomWhite {
			border-bottom: 1px solid var(--bg-white);
		}
		@media (max-width: variables.$breakpoint-md) {
			height: auto;
			word-break: break-all;
		}
	}

	.iconAndSegment {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0.5rem;
		font-weight: var(--font-weight-bold);
		&.cursorPointer {
			cursor: pointer;
		}
		@media (max-width: variables.$breakpoint-md) {
			justify-content: flex-start;
			padding-top: var(--space-inset-md);
			padding-bottom: var(--space-inset-sm);
			.name {
				order: 2;
			}
		}
	}

	.availabiltyContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.availabiltyContent {
		display: flex;
		align-items: center;
		border-radius: var(--border-radius-05);
		background-color: var(--bg-light-grey);
		padding: var(--space-inset-2xs) var(--space-inset-xs);
		color: var(--read-only);
		&.greenBackground {
			background-color: var(--bg-mint-green-full);
			color: var(--text-dark);
		}
	}

	.marginLeft {
		margin-left: var(--space-inset-xs);
	}

	.disabledColor {
		color: var(--read-only);
	}

	@media (max-width: variables.$breakpoint-md) {
		.descriptionContainer {
			padding: var(--space-inset-xs);
		}

		.whiteRow.whiteRow {
			border: 0;
			padding: var(--space-inset-md) 0;
		}

		.borderBottom {
			border-bottom: none;
		}
	}
	@media (max-width: variables.$breakpoint-md) {
		.priceBoxContainer {
			display: none;
		}
	}
}

.icon {
	width: 2rem;
	height: 2rem;
	@media (max-width: variables.$breakpoint-md) {
		width: functions.rem(20px);
		height: functions.rem(20px);
	}
}

.priceRow {
	position: sticky;
}

.primaryPrice {
	margin: var(--space-stack-xl) 0 var(--space-stack-md) 0;
	@media (max-width: variables.$breakpoint-lg) {
		margin: var(--space-stack-2xl) 0 var(--space-stack-md) 0;
	}
	@media (max-width: variables.$breakpoint-sm) {
		> div {
			display: block;
		}
	}
}

.priceBoxContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	&.adjustPrice {
		bottom: 1.5rem;
		&.stickyPriceBoxContainer {
			bottom: 2.5rem;
		}
	}
	&.stickyPriceBoxContainer {
		bottom: 1rem;
		gap: 0.2rem;
		margin: 0;
		width: 100%;
		@media (max-width: variables.$breakpoint-md) {
			display: none;
		}
	}
}

.price {
	> span {
		font-size: 12px;
	}
	&.stickyPrice {
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin-bottom: var(--space-stack-2xs);
		> div > div {
			margin-right: var(--space-stack-2xs);
		}
		> span {
			margin-right: var(--space-stack-xs);
		}
	}
}

.priceButton {
	font-size: 14px;
	@media (max-width: variables.$breakpoint-lg) {
		display: none;
	}
}

.button {
	display: flex;
	margin: 0 auto;
	width: 85%;
}

.containerFoldoutButton {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-3xl);

	@media (max-width: variables.$breakpoint-md) {
		margin-top: var(--space-stack-xl);
	}

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: var(--space-inset-3xl);
	}
}
