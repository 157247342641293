@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.container {
	border-radius: var(--border-radius-2);
	background: var(--bg-beige-light);
	height: 100%;
	min-height: functions.rem(277px);

	.image {
		position: relative;
		max-width: functions.rem(415px);
		height: 100%;
		min-height: functions.rem(277px);

		@media (min-width: variables.$breakpoint-md) {
			img {
				border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
			}
		}

		@media (max-width: variables.$breakpoint-sm) {
			width: 100%;
			height: functions.rem(200px);
			min-height: auto;
		}
	}

	.tagsAndPublishedDate {
		display: flex;
		flex-direction: column;
	}

	.textContainer {
		display: flex;
		flex-wrap: wrap;
		padding: var(--space-inset-2xl);
		height: 100%;

		.heading {
			width: 100%;
		}

		@media (max-width: variables.$breakpoint-sm) {
			padding: var(--space-inset-lg);
		}

		.linkContainer {
			align-self: flex-end;
			width: 100%;
		}
	}
}
