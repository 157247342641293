@use 'src/styles/variables' as variables;
@use 'src/styles/functions' as functions;

.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container.container.container {
	--title-color: var(--text-light);
	color: var(--text-light);
	font-variant-numeric: tabular-nums;
}

.logo {
	display: inline-flex;
	margin-bottom: var(--space-stack-3xl);
}

.copyright,
.bottomLinkItem {
	opacity: 0.8;
}

.shortText {
	margin-bottom: 6rem;
	max-width: 23em;
	text-align: center;
}

.linkGroupWrapper {
	margin-bottom: 1rem;
	padding: 0;
	list-style: none;
}

.linkGroupHeading.linkGroupHeading.linkGroupHeading {
	font-weight: var(--font-weight-light);
}

.linkGroup {
	padding: 0;
	font-weight: var(--font-weight-light);

	line-height: 1.5rem;
	list-style: none;
}

.linkItem {
	margin-bottom: var(--space-stack-sm);
}

.linkItemAnchor a,
.bottomLinkItem a {
	font-weight: var(--font-weight-light);
	text-decoration: none;

	&:link,
	&:visited,
	&:hover,
	&:active {
		color: currentcolor;
	}
}

.actions {
	margin: 2rem 0;

	p {
		margin-bottom: var(--space-stack-2xs);
		line-height: 1.5rem;
	}
}

.actionItem {
	div {
		font-weight: var(--font-weight-light);
	}

	p a::after {
		content: '' !important;
	}
}

.bottomRow {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.375rem;
	width: 100%;
}

.bottomLinkList {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0 0.5rem;
	margin: 0;
	padding: 0;

	list-style: none;

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 25rem;
	}

	@media (max-width: variables.$breakpoint-lg) {
		justify-content: space-between;
		width: 100%;

		li {
			margin-bottom: var(--space-stack-md);
		}
	}
}

.bottomLinkList,
.copyright {
	font-size: var(--font-size-small);
}

.bottomLinkList,
.brand {
	margin-bottom: 2rem;
	min-height: functions.rem(51px);
}

.bottomLinkItem a {
	margin: 1rem 1rem 1rem 0;
}

.textVariant {
	display: inline;
	opacity: 0.8;
	margin: 0;
	border: none;
	background-color: transparent;
	padding: 0;
	width: auto;
	overflow: visible;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	span {
		&:hover,
		&:active,
		&:focus {
			transition: all 0.15s ease-in-out;
			outline: none;
			border-bottom-color: var(--link-hover-effect-color);
			padding-bottom: 2px;
		}
	}
}

.brand {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	align-self: flex-start;
	margin-left: auto;
}

.copyright {
	margin-right: var(--space-inline-xl);
}

@media (max-width: 68rem) and (min-width: 36rem) {
	.brand {
		justify-content: space-between;
		align-items: center;
		margin-bottom: var(--space-stack-xl);
		width: 100%;
	}

	.bottomLinkItem {
		margin: 1rem 0;
	}

	.copyright {
		margin-right: 0;
	}
}

.emptyGridItem {
	display: none;
	@media (min-width: variables.$breakpoint-xl) {
		display: block;
	}
}
