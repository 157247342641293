@use 'src/styles/variables' as variables;

.generalContainer {
	margin-bottom: var(--space-stack-2xl);
}

.subMenuContainer {
	max-width: 74.5rem;

	@media (max-width: 1800px) {
		max-width: 62rem;
	}

	@media (max-width: 1600px) {
		max-width: 55rem;
	}

	@media (max-width: variables.$breakpoint-xl) {
		max-width: 100%;
	}
}

.codeContainer {
	border-radius: var(--border-radius-1);
	background-color: black;
	padding: var(--space-inset-md);
	color: var(--text-light);

	pre {
		margin: 0;
	}
}

.detracted {
	max-height: 11rem;
	overflow-y: hidden;
}

.expanded {
	max-height: fit-content;
}

.hidden {
	opacity: 0;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

.display {
	opacity: 1;
	transition: opacity 0.4s ease-in;
}

.tabs {
	display: flex;
	gap: 1rem;
	padding-bottom: var(--space-stack-md);
}

.tabButton {
	margin-bottom: var(--space-stack-2xs);
}

.active {
	margin: 0 6px;
	border-bottom: 2px solid var(--primary-yellow);
}

.showMoreContainer {
	display: flex;
	justify-content: center;
	margin-top: var(--space-stack-md);
}

@media (min-width: variables.$breakpoint-xl) {
	.container {
		width: 60%;
	}
}

@media (min-width: variables.$breakpoint-xl) and (max-width: variables.$breakpoint-xl) {
	.container {
		width: 65%;
	}
}
