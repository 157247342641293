@use 'src/styles/functions' as functions;

.form {
	width: 100%;
	height: 100%;
}

.textFieldContainer {
	margin-left: functions.rem(2px);
	padding-bottom: 0.5rem;
}

.hiddenField {
	display: none !important;
}

.lastOddField {
	grid-column: 1/-1;
}

.feedbackTitle {
	margin-top: functions.rem(4px);
	margin-bottom: 0;
	margin-left: 1rem;
}

.iconContainer {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	width: 100%;
}

.errorMsgContainer {
	margin-top: 2rem;
}

.errorMsgContainer,
.feedbackContainer {
	color: var(--text-dark);
}

.widget {
	padding-bottom: 1.5rem;
}

.warningMsg {
	--body-color: var(--text-light);
	border-radius: var(--border-radius-2);
	background: var(--chart-color-red);
	padding: var(--space-inset-md);
}

.consent {
	font-size: 14px;
}

.sendsafelyConsentWrapper {
	display: flex;
	justify-content: flex-end;
}
