@use 'src/styles/variables' as variables;

.container {
	display: grid;
	position: relative;
	grid-template-rows: auto auto 1fr auto var(--space-stack-md);
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: var(--space-inset-md);
	border: none;
	border-radius: var(--border-radius-card);
	background: var(--bg-light-grey);
	min-width: 18rem;
	height: 100%;

	@media (min-width: variables.$breakpoint-md) {
		max-width: 32rem;
	}
}

.header {
	display: flex;
	grid-row: 1;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	margin-bottom: var(--space-stack-md);
	border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
	padding: var(--space-inset-md) var(--space-inset-lg);
	width: 100%;
	font-weight: var(--font-weight-bold);
}

.inner {
	padding: 0 var(--space-inset-lg) var(--space-inset-xl) var(--space-inset-lg);
}

.title {
	grid-row: 2;
	height: var(--space-inset-3xl);
}
.description {
	grid-row: 3;
	padding-top: var(--space-inset-sm);
}

.content {
	padding-right: var(--space-inset-lg);
	padding-left: var(--space-inset-lg);
}

.contentList {
	display: table;
	grid-template-rows: auto auto auto;
	grid-row: 4;
	align-self: stretch;
	margin-right: var(--space-inset-md);
	margin-left: var(--space-inset-md);
	border-top: 1px var(--border-color-light) solid;
	border-bottom: 1px var(--border-color-light) solid;
	padding-top: var(--space-inset-md);
	padding-bottom: var(--space-inset-md);
}
.educationLink {
	grid-row: 5;
}
.contentRow {
	display: table-row;
}
.contentHeader {
	display: table-cell;
	padding-right: var(--space-inset-md);
	font-weight: var(--font-weight-bold);
}

.footer {
	margin-top: auto;
	padding: var(--space-inset-md);
	padding-bottom: var(--space-inset-xl);
	width: 100%;
}
