@use 'src/styles/animations' as animations;

.container {
	display: flex;
	position: relative;
	flex-direction: column;
	vertical-align: top;
	padding: 0;
	width: 100%;
}

.hiddenSelect {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
}

/* INPUT */
.input {
	z-index: 5;
	cursor: pointer;
	box-sizing: border-box;
	margin: 0;
	outline: none;
	box-shadow: none;
	border: none;
	border-radius: var(--border-radius-input);
	background-color: transparent;
	padding: 0;
	padding-top: var(--space-stack-md);
	padding-left: var(--space-inline-md);
	width: 100%;
	height: 4rem;
	resize: none;
	color: var(--text-dark);
	line-height: 2;
	text-overflow: ellipsis;
}

.input:-webkit-autofill {
	transition: background-color 5000s;
}

.input.small {
	padding-top: var(--space-stack-sm);
	height: 40px;
}

/* SELECT */
.fieldGroup {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
	min-width: 12rem;
	height: 3.5rem;
}

.field {
	display: flex;
	position: relative;
	align-items: center;
	transition:
		all 0.15s ease-in-out,
		border 0.3s ease-in-out;
	border-radius: var(--border-radius-input);
	background-color: var(--bg-white);
	padding-right: var(--space-inset-md);
	overflow: hidden;
	white-space: nowrap;
}

.bordered .field {
	border: 1px solid var(--border-color);
}

.field:hover {
	border-color: var(--border-hovered-color);
}

.field.active {
	border-color: var(--border-hovered-color);
}

.field.disabled {
	cursor: not-allowed;
	border-color: var(--disabled-color);
}

.field.invalid:not(.disabled) {
	border-color: var(--danger-color);
}

.field.disabled .input,
.field.disabled label {
	cursor: not-allowed;
	color: var(--disabled-color);
}

.field.disabled .icon,
.field.disabled .displayLabel {
	color: var(--disabled-color);
}

.field.readOnly .input {
	cursor: text;
	color: var(--text-dark);
}

.field.readOnly label {
	cursor: auto;
	color: var(--read-only);
}

.field.readOnly .icon {
	color: var(--read-only);
}

/* LABEL */
.labelWrapper {
	position: relative;
	width: 100%;
}

.label {
	position: absolute;
	top: 2px;
	transform: translateY(100%);
	transform-origin: 0 100%;
	transition: all 0.15s ease-in-out;
	pointer-events: none;
	color: var(--read-only);
	line-height: normal;
	text-align: initial;
}

.label.active,
.label.not-empty {
	top: 8px;
	transform: scale(0.85);
	transform-origin: 0 0;
	background-color: var(--bg-white);
	width: 100%;
}

.small.label {
	top: -7px;
}

.small.label.active,
.small.label.not-empty {
	top: 4px;
}

/* HELPERTEXT */
.helperText {
	transition: all 0.15s ease-in-out;
	margin-left: var(--space-inline-md);
	color: var(--text-dark);
	font-size: var(--font-size-body-sm);
}

.helperText.invalid:not(.disabled) {
	color: var(--danger-color);
}

/* ICON */
.iconGroup {
	display: flex;
	align-self: center;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.714rem;
	color: var(--read-only);
}

/* LIST */
.listbox {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: var(--z-index-3);
	animation: fadeIn ease 0.3s;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
	border-top: none;
	border-radius: 0 0 var(--border-radius-input) var(--border-radius-input);
	background: var(--bg-white);
	width: 100%;
	overflow-y: auto;
}

.bordered .listbox {
	border: 1px solid var(--border-color);
}

.listbox.active {
	display: block;
}

/* OPTION */
.displayLabel {
	position: absolute;
	padding-top: var(--space-stack-lg);
	padding-left: var(--space-inset-md);
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.displayLabelSmall {
	padding-top: var(--space-stack-md);
}

.option {
	display: flex;
	justify-content: flex-start;
	border-top: none;
	border-right: none;
	border-bottom: 1px solid var(--border-hovered-color);
	border-left: none;
	background-color: transparent;
	padding: var(--space-inset-md);
	width: 100%;
	color: var(--text-dark);
	font-weight: var(--font-weight-light);
	text-align: left;
}

.option.small {
	padding: var(--space-inset-xs);
}

.option:hover {
	background: var(--selected-color);
}

.option:last-child {
	border-bottom: none;
}

.option.active {
	position: relative;
	overflow: hidden;
	font-weight: var(--font-weight-bold);
}

.option.focused {
	background: var(--selected-color);
}

.option.active::after {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--highlight-color);
	width: 4px;
	height: 100%;
	content: '';
}

.result {
	pointer-events: none;
}

.result:hover {
	background: var(--selected-color);
}

.result:last-child {
	border-bottom: none;
}

.showingResult {
	transition-property: border-radius;
	border-radius: var(--border-radius-input) var(--border-radius-input) 0 0;
}
.validationMessage {
	bottom: -10%;
	transform: translateY(0);
	transition: 0.2s;
	height: 1rem;
	color: var(--danger-color);
	font-size: 0.75rem;
}
