@use 'src/styles/variables' as variables;

.heading {
	margin-bottom: var(--space-stack-lg);
	@media (max-width: variables.$breakpoint-md) {
		text-align: center;
	}
}

.input {
	margin-bottom: var(--space-stack-xl);
}

.numberInputFields input::-webkit-outer-spin-button,
.numberInputFields input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.numberInputFields input[type='number'] {
	-moz-appearance: textfield;
}

.resultExplanation {
	.calculation {
		padding: var(--space-inline-md) 0;

		& > div {
			display: flex;
			justify-content: space-between;
			margin: var(--space-stack-md) 0;
		}
	}

	.floatRight {
		float: right;
		text-align: right;
	}
}
.label {
	font-weight: var(--font-weight-bold);
}

.result {
	margin-bottom: var(--space-stack-xl);
}

.resultValue {
	font-weight: var(--font-weight-bold);
}

.resultValueMoms {
	margin-top: 1.5rem;
	font-weight: var(--font-weight-bold);
	@media (max-width: variables.$breakpoint-lg) {
		margin-top: 0;
	}
}

.justifyContentFlexEnd {
	justify-content: flex-end !important;
}

.chartContainer {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	min-height: 15vh;
	@media (max-width: variables.$breakpoint-xl) {
		margin-top: var(--space-stack-2xl);
		margin-bottom: var(--space-stack-2xl);
	}
}

.chartContainer canvas:first-child {
	width: 11rem !important;
	height: 11rem !important;
}

.chartContainer canvas:nth-child(2) {
	position: absolute;
	width: 7.5rem !important;
	height: 7.5rem !important;
}
