@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.card.card.card {
	display: flex;
	flex-wrap: wrap;
	padding: var(--space-inset-6xl) var(--space-inset-5xl);

	@media (max-width: variables.$breakpoint-lg) {
		border-radius: 0;
		padding: var(--space-inset-3xl);
	}

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-3xl) var(--space-inset-xl);
	}
}

.imageWrapper {
	display: block;
	border: 0.5rem solid var(--bg-white);
	border-radius: var(--border-radius-round);
	min-width: functions.rem(214px);
	max-width: functions.rem(214px);
	min-height: functions.rem(214px);
	max-height: functions.rem(214px);
	img {
		border-radius: var(--border-radius-round);
	}
	@media (max-width: variables.$breakpoint-xl) {
		margin-bottom: 1.63rem;
		min-width: 10rem;
		max-width: 10rem;
		min-height: 10rem;
		max-height: 10rem;
	}

	@media (max-width: variables.$breakpoint-sm) {
		order: 1;
	}
}

.heading {
	@media (max-width: variables.$breakpoint-sm) {
		order: 2;
	}
}

.headingWrapper {
	display: flex;
	gap: var(--space-inset-md);

	@media (min-width: variables.$breakpoint-xl) {
		margin-bottom: var(--space-inset-xl);
	}

	@media (min-width: variables.$breakpoint-max) {
		gap: var(--space-inset-xl);
	}

	@media (max-width: variables.$breakpoint-sm) {
		flex-direction: column;
	}
}

.linkWrapper {
	margin-bottom: var(--space-stack-2xl);
	&:last-child {
		margin-bottom: 0;
	}
}
