.switchContainer {
	display: flex;
	align-items: center;
	gap: var(--space-inset-xs);
	cursor: pointer;
}

.switchInput {
	opacity: 0;
	width: 0;
	height: 0;
}

.switchSlider {
	display: inline-block;
	position: relative;
	transition: background-color 0.3s;
	border-radius: var(--space-inset-lg);
	background-color: var(--border-color);
	width: var(--space-inset-2xl);
	height: var(--space-inset-lg);

	&:hover {
		background-color: var(--read-only);
	}
}

.switchSlider::before {
	position: absolute;
	top: 2px;
	left: 2px;
	transition: transform 0.3s;
	border-radius: var(--border-radius-round);
	background: var(--bg-white);
	width: 20px;
	height: 20px;
	content: '';
}

.switchInput:checked + .switchSlider {
	background-color: var(--action-color);

	&:hover {
		background-color: var(--action-hovered-color);
	}
}

.switchInput:checked + .switchSlider::before {
	transform: translateX(var(--space-inset-lg));
}
