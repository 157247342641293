@use 'src/styles/variables' as variables;

$imageSize: 36rem;

.container {
	margin: 0 auto;
	max-width: 81.25rem;

	@media (max-width: variables.$breakpoint-md) {
		max-width: 23rem;
	}
}

@mixin styling($imageSize) {
	.image {
		position: relative;
		@media (min-width: (variables.$breakpoint-md + 1)) {
			width: $imageSize;
			min-width: $imageSize;
			height: $imageSize;
			min-height: $imageSize;
		}
	}
	.wrapper {
		display: flex;
		position: relative;
		justify-content: flex-end;
		align-items: center;
		@media (max-width: variables.$breakpoint-md) {
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: center;
			align-self: center;
		}
	}
	.verticalCard.verticalCard {
		@media (max-width: variables.$breakpoint-md) {
			top: calc($imageSize / -1.75);
			border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
			padding: 0;
		}
		@media (max-width: variables.$breakpoint-min) {
			top: calc($imageSize / -2.1);
			border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
			padding: 0;
		}
	}
	.horizontalCard.horizontalCard {
		@media (min-width: (variables.$breakpoint-md + 1)) {
			display: flex;
			position: relative;
			left: calc($imageSize / 2);
			flex-direction: row;
			align-items: center;
			margin-left: calc($imageSize / -2);
			border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);
			padding: 0;
			width: 100%;
			height: $imageSize;
		}

		.contentWrapper {
			@media (max-width: variables.$breakpoint-md) {
				margin-top: calc($imageSize / 1.75);
				padding: var(--space-inset-lg);
			}
			@media (max-width: variables.$breakpoint-min) {
				margin-top: calc($imageSize / 2.1);
				padding: var(--space-inset-lg);
			}
		}
	}
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	padding-right: var(--space-inset-lg);
	padding-bottom: 0;
	padding-left: var(--space-inset-2xl);

	.contactRow {
		align-items: baseline;

		.greeting {
			z-index: 30;
			padding-top: var(--space-stack-sm);
			font-style: italic;
			font-size: var(--header-font-size-ingress);
		}
	}
}

.imageWrapper {
	@media (max-width: variables.$breakpoint-md) {
		z-index: 20;
		border: var(--border-radius-2) solid transparent;
		border-radius: var(--border-radius-round);
		padding: var(--space-stack-md);
		overflow: hidden;
	}
	@media (min-width: (variables.$breakpoint-md + 1)) {
		z-index: 0;
	}

	.image {
		z-index: 10;
		border: var(--border-radius-2) solid transparent;
		border-radius: var(--border-radius-round);
		background: white;
		width: 23rem;
		height: 23rem;
		overflow: hidden;
	}
}

@media (min-width: (variables.$breakpoint-md + 1)) {
	.heading {
		padding-right: 10rem;
		width: 100%;
	}
	.text.text.text {
		padding-right: 10rem;
	}
	.contactRow.contactRow {
		display: flex;
		flex-wrap: wrap;
		padding-right: var(--space-inline-2xl);
	}
	.button.button.button {
		margin-right: var(--space-inline-md);
	}
}

@media (min-width: (variables.$breakpoint-xl + 1)) {
	@include styling($imageSize: 20rem);
	.hasSubMenuV1 {
		@include styling($imageSize: 20rem);
	}
}

@media (max-width: variables.$breakpoint-xl) {
	@include styling($imageSize: 20rem);
}

@media (max-width: variables.$breakpoint-md) {
	.button.button.button {
		margin-bottom: var(--space-stack-lg);
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}
	.text.text.text {
		padding-bottom: var(--space-stack-xl);
	}
	.image {
		span {
			display: flex !important;
		}
	}
}
