@use 'src/styles/variables' as variables;

.image {
	padding: 22%;
	width: 100%;
	height: 100%;
}

.imageWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	margin-top: var(--space-stack-xl);
	margin-bottom: var(--space-stack-xl);
	border-radius: var(--border-radius-round);
	background: var(--bg-mint-green-full);
	aspect-ratio: 1/1;
	width: 100%;
	max-width: 15.625rem;
	height: 100%;
}

.container {
	position: relative;
	padding: var(--space-stack-md) 0;
	width: 100%;
	height: 100%;
	overflow-y: visible;
}

.stickyBox {
	position: sticky;
	top: var(--global-menu-height);
	align-self: flex-start;
	z-index: 1;
	width: 100%;
	height: fit-content;
	@media (min-width: variables.$breakpoint-lg) {
		position: relative;
		top: 0;
	}
}

.card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 35rem;
}

.button {
	width: 100%;
}
