@use 'src/styles/variables' as variables;

.container {
	margin-bottom: var(--space-stack-xl);
	width: 100%;
}

.selectedCompany {
	opacity: 0;
	transition:
		opacity 0.2s linear,
		height 0.3s ease-out;
	height: 0;

	&.showSelectedCompany {
		opacity: 1;
		transition: all 0.5 ease-in;
		height: auto;
	}
}

.greenRow {
	margin-bottom: var(--space-stack-xl);
	border-radius: var(--border-radius-05);
	background-color: var(--bg-dark-green-full);
	padding: var(--space-inline-md);
	width: 100%;
}

.headerText {
	--title-color: var(--text-light);
}

.changeCompanyItems {
	margin-bottom: var(--space-stack-xl);
}

.text {
	padding: 0 var(--space-inline-md);
	ul {
		padding: 0;
	}
}

.radiobuttonGrid {
	display: grid;
	grid-template-columns: auto auto auto auto;
	margin-bottom: var(--space-stack-xl);
	padding: 0 var(--space-inset-lg);
	.radiobutton {
		padding-bottom: var(--space-stack-md);
	}

	@media (max-width: (variables.$breakpoint-lg + 1)) {
		grid-template-columns: auto auto auto;
	}
	@media (max-width: variables.$breakpoint-md) {
		grid-template-columns: auto auto;
	}
	@media (max-width: variables.$breakpoint-sm) {
		grid-template-columns: auto;
		font-size: 20px;
	}
}
