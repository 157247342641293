@use 'src/styles/variables' as variables;

.labels {
	display: flex;
	gap: 1rem;
	background-color: var(--bg-white);
}

.tabButton {
	border: none;
	border-radius: var(--border-radius-05) var(--border-radius-05) 0 0;
	background: none;
	padding: var(--space-inset-sm) var(--space-inset-md) var(--space-inset-xs) var(--space-inset-md);
	color: var(--primary-green);
	font-weight: var(--font-weight-bold);
	white-space: nowrap;

	&:hover {
		cursor: pointer;
	}
}

.active {
	background-color: var(--bg-beige-light);
}

.codeContent {
	border-radius: var(--border-radius-05) var(--border-radius-05) var(--border-radius-05) var(--border-radius-05);
	background-color: var(--bg-beige-light);
	padding: var(--space-inset-xl) var(--space-inset-xl) var(--space-inset-xs) var(--space-inset-xl);
}

.firstItem {
	border-radius: 0 var(--border-radius-05) var(--border-radius-05) var(--border-radius-05);
}
