@use 'src/styles/variables' as variables;

.heading {
	@media (max-width: variables.$breakpoint-md) {
		text-align: center;
	}
}

.input {
	margin-bottom: var(--space-stack-xl);
}

.memberOfChurch {
	margin-top: var(--space-inset-md);
	margin-bottom: var(--space-stack-sm);
}

.label {
	font-weight: var(--font-weight-bold);
}

.numberInputFields input::-webkit-outer-spin-button,
.numberInputFields input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.numberInputFields input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
}

.errorMessage {
	--body-color: var(--danger-color);
	margin-top: var(--space-stack-md);
	color: var(--body-color);
}

.churchSelector {
	margin-bottom: var(--space-stack-xl);
}
