.checkbox {
	display: inline-flex;
	padding-bottom: var(--space-stack-xs);
	color: var(--text-dark);
	font-size: var(--font-size-body-lg);

	&.label-color--dark {
		color: var(--text-dark);
	}

	&.label-color--light {
		color: var(--text-light);
	}
}

.box {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	border-radius: var(--border-radius-025);
	background: var(--bg-white);
	width: 22px;
	height: 22px;

	&.bordered {
		border: 1px solid var(--border-color);
	}
}

.box:hover {
	border-color: var(--border-hovered-color);
}

.box.checked {
	transition: all 0.3s ease-in-out;
	border-color: var(--action-color);
	background: var(--action-color);
}

.box.checked:hover {
	border-color: var(--border-hovered-color);
	background: var(--bg-dark-green-full);
}

.box .icon {
	visibility: hidden;
}

.box.checked .icon {
	visibility: visible;
	color: var(--text-light);
}

.box.bordered:not(.checked) {
	border: 1px solid var(--border-color);
}

.label {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: auto;
}

.input {
	clip: rect(0 0 0 0);
	position: absolute;
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	overflow: hidden;
	white-space: nowrap;
}

.input:focus + label {
	outline: -webkit-focus-ring-color auto 1px;
	outline-style: auto;
}

.input:focus:not(:focus-visible) + label {
	outline: none;
}

.labelContainer {
	padding-left: var(--space-inline-md);
}
