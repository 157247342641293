@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.wrapperDesktop {
	overflow-x: auto;
}

.table {
	margin: 0 auto;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	max-width: 60rem;

	@media (min-width: variables.$breakpoint-xl) {
		max-width: 80rem;
	}

	thead {
		th {
			padding: var(--space-stack-sm) 0;
			min-width: functions.rem(120px);
		}
	}
	tbody {
		tr {
			&:not(&:last-of-type) {
				border-bottom: 1px solid var(--border-color);
			}
			td:first-of-type {
				min-width: 170px;
			}
			td:not(td:first-of-type) {
				text-align: center;
			}
			td {
				padding: var(--space-inset-sm) 0;
			}
		}
	}
}

.imageCol {
	position: relative;
	vertical-align: top;
}

.btnContainer.btnContainer.btnContainer {
	display: flex;
	justify-content: center;
	a {
		margin-right: 0;
	}
}

.imageAsset {
	display: flex;
	justify-content: center;
}

.wrapperMobile {
	display: none;
}

.mobileTableWrapper {
	margin-bottom: var(--space-stack-xl);
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-1);
	padding: 0 var(--space-inset-md);
}

@media (max-width: variables.$breakpoint-lg) {
	.wrapperDesktop {
		display: none;
	}

	.wrapperMobile {
		display: block;
	}

	.imageAsset {
		display: flex;
		justify-content: left;
	}

	.table {
		thead {
			width: 100%;
			tr {
				th {
					min-width: unset;
				}
				th:first-of-type {
					width: 100%;
					min-width: 170px;
				}
			}
		}
		tbody {
			display: table-row-group;

			&.closed {
				display: none;
			}
		}
	}
}

.icon {
	transition: transform 0.3s ease;

	&.closed {
		transform: rotate(180deg);
	}
}
