@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.image {
	position: relative;
	cursor: pointer;
	min-height: 19rem;

	img {
		border-radius: var(--border-radius-card);
	}
}

.playIcon {
	$icon-width: 2rem;
	position: absolute;
	top: calc(50% - $icon-width);
	left: calc(50% - $icon-width);
	z-index: var(--z-index-2);
}

.closeIcon {
	position: absolute;
	top: -2.5rem;
	right: 0;
	z-index: var(--z-index-max);
}

.overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-index-max);
	animation: fadeIn ease 0.3s;
	background: rgba(248, 241, 232, 0.75);
	width: 100vw;
	height: 100vh;
	overflow-y: hidden;
}

.showVideo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.videoWrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 auto;
	width: 100%;
	max-width: 60rem;

	@media (max-width: variables.$breakpoint-lg) {
		width: calc(100% - 2rem);
	}
}

.childTheme {
	.image {
		img {
			@media (min-width: variables.$breakpoint-md) {
				border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
			}
		}
	}

	.imageOverlay {
		border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
	}

	.textContainer {
		padding-right: var(--space-inset-xl);
		padding-left: var(--space-inset-xl);
	}
}

.image-height--1,
.image-height--2 {
	min-height: 19rem;
}

.image-height--3 {
	min-height: 15.625rem;
}

.image-height--4 {
	min-height: 13.75rem;
}

.image-height--1,
.image-height--2,
.image-height--3,
.image-height--4 {
	@media (max-width: variables.$breakpoint-lg) {
		min-height: 25rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		min-height: 15rem;
	}
}

.textContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: var(--space-inset-xl) 0;
}

.buttonContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
}

.imageOverlay {
	position: absolute;
	inset: 0;
	border-radius: var(--border-radius-2);
	background: linear-gradient(9deg, rgba(0, 0, 0, 0.41) 27%, rgba(0, 56, 36, 0) 100%);
	height: 100%;
}
