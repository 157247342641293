@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.menuVersionV3 {
	--animation-duration: 0.6s;
	--top-initial: calc(var(--global-menu-height) + var(--global-menu-offset) * 2 + 1.5rem);
	--border-radius: 1.7rem;
	--border-radius-inital: 0 var(--border-radius) var(--border-radius) 0;
	--initial-menu-width: 5.25rem;
	--expanded-menu-width: calc(100vw - var(--global-menu-offset) * 2);

	&.subMenuMobile {
		position: fixed;
		top: var(--top-initial);
		left: 0;
		transition: none;
		border-radius: var(--border-radius-inital);
		background-color: var(--bg-white-90);
		width: var(--initial-menu-width);
		height: auto;

		&.hideGlobalMenu,
		&.isMenuOpen {
			right: var(--global-menu-offset);
			left: var(--global-menu-offset);
			border-radius: var(--border-radius);
			background-color: var(--bg-white);
			width: var(--expanded-menu-width);

			.inner {
				border-radius: var(--border-radius);
				background-color: var(--bg-white);
				padding: var(--space-inset-md);
			}

			.title {
				opacity: 1;
				transition: opacity var(--animation-duration) ease-in-out;
			}
		}

		&.hideGlobalMenu {
			top: var(--global-menu-offset);
			animation: expandVertically var(--animation-duration) ease-in-out;
			height: var(--global-menu-height);

			.inner {
				height: var(--global-menu-height);
			}
		}

		&.isMenuOpen {
			border-radius: var(--border-radius);
			height: auto;

			.inner {
				height: auto;
			}
		}

		&.slideUp {
			top: var(--global-menu-offset);
			transition: top 0.3s ease;
		}

		&.slideDown {
			transition: top 0.3s ease;
		}

		&.expandAndOpen {
			animation: expandAndOpen var(--animation-duration) ease-in-out;
		}

		&.minimize {
			animation: minimize var(--animation-duration) ease-in-out;
		}
	}

	.inner {
		border-radius: var(--border-radius-inital);
		background-color: unset;
		padding: var(--space-inset-xs) var(--space-inset-md) var(--space-inset-xs) 2.5rem;
		height: auto;
	}

	.navHeader {
		background-color: unset;
		padding: var(--space-inset-xs) 0;
		height: 100%;
		height: calc(var(--global-menu-height) - 2rem);
	}

	.title {
		opacity: 0;
		transition: none;
	}

	.links {
		background-color: unset;
	}
}

.subMenuMobile {
	position: sticky;
	top: var(--global-menu-height);
	flex-direction: column;
	z-index: var(--z-index-10);
	transition: top 0.3s ease;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
	background-color: var(--bg-white);
	width: 100%;
	height: var(--global-menu-height);

	&.hideGlobalMenu {
		top: var(--global-menu-offset);
	}

	&.zIndex {
		z-index: var(--z-index-7);
	}
}

.inner {
	background-color: var(--bg-white);
	padding: 0 var(--space-inset-md);
}

.navHeader {
	display: flex;
	align-items: center;
	background-color: var(--bg-white);
	padding: var(--space-inset-md) 0;
	height: var(--global-menu-height);
}

.activeLink.activeLink.activeLink {
	font-weight: var(--font-weight-bold);
}

.links {
	opacity: 0;
	transition:
		opacity 0.2s linear,
		max-height 0.3s ease-out;
	background-color: var(--bg-white);
	max-height: 0;
	overflow: hidden;
}

.isMenuOpen {
	transition: height var(--animation-duration) ease-in-out;

	.links {
		opacity: 1;
		transition:
			opacity 0.2s ease-in-out,
			max-height var(--animation-duration) ease-in-out;
		transition-delay: 0.1s;
		max-height: 60rem;
	}
}

.linkGroup {
	&:last-of-type {
		border-bottom: none;
	}
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--space-inset-xs) 0;

	&:first-of-type {
		padding-top: 0;
	}
}

.itemIcon {
	transition: transform 0.3s;

	&.rotate {
		transform: rotate(90deg);
	}
}

.subItem {
	padding: var(--space-inset-2xs) 0;
}

.subItemsGroup {
	display: flex;
	flex-direction: column;
	opacity: 0;
	transition:
		height 0.6s ease-in,
		opacity 0.6s ease-in,
		padding 0.6s ease-in;
	padding: 0 var(--space-inset-md);
	height: 0;
	overflow: hidden;

	&.isLevelOpen {
		opacity: 1;
		transition:
			height 0.6s ease-out,
			opacity 0.6s ease-out,
			padding 0.6s ease-out;
		padding: 0 var(--space-inset-md) 0.625rem;
		height: auto;
		height: calc-size(auto);
	}
}

@keyframes expandVertically {
	0% {
		top: var(--top-initial);
		width: var(--initial-menu-width);
		height: auto;
	}

	30% {
		height: var(--global-menu-height);
	}

	100% {
		top: var(--global-menu-offset);
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}
}

@keyframes expandAndOpen {
	0% {
		width: var(--initial-menu-width);
		height: auto;
	}

	100% {
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}
}

@keyframes minimize {
	0% {
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}

	5% {
		right: auto;
		left: 0;
	}

	100% {
		top: var(--top-initial);
		width: var(--initial-menu-width);
	}
}
