.chip {
	display: inline-flex;
	align-items: center;
	border-radius: var(--border-radius-1);
	background-color: var(--background-color);
	padding: 0 var(--space-inset-sm);
	max-width: 100%;
	height: 1.5rem;

	.chipText {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.iconHitBox {
		display: flex;
		align-items: center;
		cursor: pointer;
		height: 1.5rem;
	}

	.iconHitBox--default {
		padding: 0 var(--space-inset-xs);
	}

	.iconHitBox--small {
		padding: 0 var(--space-inset-3xs);
	}

	.icon {
		height: 1rem;
	}
}
