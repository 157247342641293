.content {
	> h2,
	> h3,
	> h4,
	> h5,
	> h6 {
		margin-top: 3rem;
	}
}

.relatedWords {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--space-stack-2xl);
	border-radius: var(--border-radius-2);
	background-color: var(--bg-beige-extra-light);
	padding: var(--space-inset-xl);

	.keyWords {
		--body-color: var(--primary-green);
		display: flex;
		align-content: center;
		align-items: center;
		gap: 1rem;
	}
}
