@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.group,
.center {
	margin: 0 auto;
	max-width: 60rem;
}

.btnPosition {
	display: flex;
	justify-content: center;
	margin-top: 8rem;

	@media (max-width: variables.$breakpoint-xl) {
		margin-top: 5rem;
	}
}

.smallTitleWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.hr {
	margin: 0 0 var(--space-stack-xl);
	background-color: var(--border-color);
	width: 9.25rem;
	height: 1px;
}

.group {
	@media (min-width: variables.$breakpoint-md) {
		column-gap: 8rem;
		row-gap: 8rem;
	}
}

.gridContainer {
	@media (min-width: variables.$breakpoint-xl) {
		column-gap: 4rem;
	}
}

.grid {
	column-gap: 8rem;
	row-gap: 8rem;

	@media (max-width: variables.$breakpoint-max) {
		column-gap: var(--space-inset-3xl);
		row-gap: var(--space-inset-3xl);
	}
}

.gap-size--md {
	column-gap: 8rem;
	row-gap: 8rem;

	@media (max-width: variables.$breakpoint-max) {
		column-gap: var(--space-inset-3xl);
		row-gap: var(--space-inset-3xl);
	}
}

.gap-size--sm {
	column-gap: var(--space-inset-xl);
	row-gap: var(--space-inset-xl);
}

.nr-of-grid-items--5 {
	.gridItem {
		grid-column: span 1;

		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 2;
			&:nth-child(4) {
				grid-column: 2 / span 2;
			}
		}
	}
}

.nr-of-grid-items--7 {
	.gridItem {
		grid-column: span 1;

		@media (min-width: variables.$breakpoint-lg) {
			grid-column: span 2;

			&:nth-child(5) {
				grid-column: 2 / span 2;
			}
		}
	}
}

.oddNrOfCards {
	.gridItem {
		@media (min-width: variables.$breakpoint-md) and (max-width: variables.$breakpoint-lg) {
			grid-column: span 2;
			&:last-child:nth-child(odd) {
				grid-column: 2 / span 2;
			}
		}
	}
}

.fadeIn {
	opacity: 0;
	animation: fadeIn 0.5s linear forwards;
}
