.common {
	font-weight: var(--font-weight-light);

	&.margin-bottom--none {
		margin-bottom: 0;
	}

	&.margin-bottom--2xs {
		margin-bottom: var(--space-stack-2xs);
	}

	&.margin-bottom--xs {
		margin-bottom: var(--space-stack-xs);
	}

	&.margin-bottom--sm {
		margin-bottom: var(--space-stack-sm);
	}

	&.margin-bottom--md {
		margin-bottom: var(--space-stack-md);
	}

	&.margin-bottom--lg {
		margin-bottom: var(--space-stack-lg);
	}

	&.margin-bottom--xl {
		margin-bottom: var(--space-stack-xl);
	}

	&.margin-bottom--2xl {
		margin-bottom: var(--space-stack-2xl);
	}

	&.margin-bottom--3xl {
		margin-bottom: var(--space-stack-3xl);
	}
}

// Size
.size--default {
	font-size: 1rem;
	line-height: 1.5rem;
}

.size--small {
	font-size: var(--font-size-small);
	line-height: 1.3125rem;
}

.size--ingress {
	font-size: 1.1125rem;
	line-height: 1.75rem;
}

.justify {
	text-align: justify;
	text-justify: inter-word;
}

.bold {
	font-weight: var(--font-weight-bold);
}

.muted {
	opacity: 0.7;
}
