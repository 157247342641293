.container {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--space-stack-xl);
	@media (min-width: 62rem) {
		margin-bottom: var(--space-stack-3xl);
	}
}

.testimonial--1 {
	.quote {
		font-weight: var(--font-weight-heavitas-extra-bold);

		@media (min-width: 62rem) {
			font-size: 4.125rem;
		}
	}
}

.singleDetails {
	font-weight: var(--font-weight-bold);
}

.name {
	font-weight: var(--font-weight-bold);
}

.details {
	display: flex;
	align-items: center;
}

.icon {
	margin-right: 1.5rem;
}
