@use 'src/styles/variables' as variables;

.reviewItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--space-stack-xl);
	height: 100%;
	min-height: calc(var(--space-inset-6xl) * 2);
	text-align: left;

	@media screen and (min-width: variables.$breakpoint-md) {
		flex-direction: row;
		align-items: center;
		min-height: var(--space-inset-7xl);
	}
}

.reviewHeading {
	@media screen and (min-width: variables.$breakpoint-md) {
		width: 30%;
	}
}
.reviewText {
	text-align: left;
	@media screen and (min-width: variables.$breakpoint-md) {
		width: 70%;
	}
}
.review {
	margin-bottom: var(--space-inset-lg);
	font-size: var(--header-font-size-h5);
	line-height: var(--font-size-h3);
	&::before,
	&::after {
		content: '"';
	}
}
