@use 'src/styles/variables' as variables;

.integratorCard {
	display: flex;
	flex-direction: column;
	gap: var(--space-inline-md);
}

.integratorInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: var(--space-stack-sm);
	height: 100%;
	color: var(--text-dark);

	@media screen and (min-width: variables.$breakpoint-md) {
		gap: var(--space-stack-md);
	}

	.integratorLogo {
		display: flex;
		align-items: center;
		height: 10.625rem;

		img {
			margin: auto;
			width: 100%;
			max-width: 18.75rem;
			max-height: 10.625rem;
			object-fit: contain;
		}
	}

	.integratorAbout {
		display: flex;
		flex-direction: column;
		gap: var(--space-stack-xs);
		height: 100%;

		.about {
			display: flex;
			flex-direction: column;
			gap: var(--space-stack-xs);

			.readMore {
				color: var(--primary-green);
				&::after {
					padding-left: 0.25rem;
					content: '›';
				}
			}

			span {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
				-webkit-line-clamp: 3;
				line-clamp: 3;
			}
		}
	}

	.integratorContact {
		display: flex;
		flex-direction: column;
		gap: var(--space-stack-2xs);
		border-top: 1px solid var(--border-color-light);
		padding-top: var(--space-stack-md);
		color: var(--action-color);

		.integratorContactIcon {
			display: flex;
			gap: var(--space-stack-sm);

			a,
			span {
				max-width: 15rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
