.menuContent {
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 0.5rem;
	opacity: 0;
	width: 100%;
	height: 0;
	overflow: hidden;

	&.isMenuOpen {
		opacity: 1;
		padding: var(--space-inset-md) var(--space-inset-lg);
		height: calc(100% - 7rem);
		overflow-y: auto;
	}
}

.mainLinks {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	margin-bottom: var(--space-stack-lg);
}

.links {
	opacity: 0;
	transition:
		margin-top 0.1s ease-out 0.3s,
		height 0.5s ease-out 0s,
		opacity 0.3s ease-out 0.2s;
	overflow: hidden;

	&.open {
		opacity: 1;
		transition:
			margin-top 0.1s ease-in 0s,
			height 0.5s ease-in 0.1s,
			opacity 0.5s ease-in 0s;
		margin-top: 1.5rem;
	}
}

.titleWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	border-bottom: 1px solid var(--border-color-70);
	background-color: transparent;
	padding: 0;
	width: 100%;
}

.title {
	color: var(--text-heading-color);
	font-weight: var(--font-weight-heavitas-extra-bold);
	font-size: 0.75rem;
	line-height: 2.05rem;
	font-family: var(--font-family-heavitas);
	text-transform: lowercase;
}

.icon {
	transition: transform 0.5s;

	&.rotate {
		transform: rotate(90deg);
	}
}

.subLinks {
	display: flex;
	flex-direction: column;

	&:last-of-type {
		margin-bottom: var(--space-stack-md);
	}
}

.space {
	width: 100%;
	height: 1rem;
}
