@use 'src/styles/variables' as variables;

.filterContainer {
	@media (min-width: variables.$breakpoint-md) {
		padding-right: var(--space-stack-xl);
	}
}
.searchFieldWrapper {
	margin-top: var(--global-header-height);
	margin-bottom: var(--space-stack-lg);
	@media (max-width: variables.$breakpoint-md) {
		margin-top: 0;
	}
}

.filterContent {
	@media (max-width: variables.$breakpoint-md) {
		transition: max-height 0.8s ease-in-out;
		max-height: 0;
		overflow: hidden;
		&.show {
			max-height: 1600px;
		}
	}
}

.filterHeader {
	display: none;
	@media (max-width: variables.$breakpoint-md) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: var(--space-stack-sm);
		cursor: pointer;
		padding-bottom: var(--space-stack-sm);
		color: var(--text-primary-green);
		font-weight: var(--font-weight-bold);
	}
}
