@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

$outlined-border-width: 0.125rem;

.btn {
	display: inline-block;
	position: relative;
	cursor: pointer;
	max-width: 100%;
	font-weight: var(--font-weight-bold);
	font-size: 1rem;
	line-height: 1.5125rem;
	text-align: center;
	text-decoration: none;

	.inner {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.variant--outlined {
		transition: 0.5s;
		border: solid $outlined-border-width;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		&.size--small {
			padding: functions.rem(0.16px) 0.9rem;
		}
	}

	&.variant--filled {
		transition: 0.5s;
		padding-top: calc(0.5rem + $outlined-border-width);
		padding-bottom: calc(0.5rem + $outlined-border-width);

		&.size--small {
			padding: functions.rem(1.6px) 1rem;
		}
	}

	&.variant--outlined,
	&.variant--filled {
		border-radius: var(--border-radius-button);
		padding-right: 2rem;
		padding-left: 2rem;
		min-width: 5rem;

		&.size--large {
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
		}
	}

	&.variant--text {
		margin-right: 0;
		text-align: left;
	}

	&.variant--link {
		font-weight: var(--font-weight-light);
		line-height: 1.9rem;
		text-align: left;

		@media (max-width: variables.$breakpoint-xl) {
			line-height: 1.75rem;
		}

		&.size--large {
			font-weight: var(--font-weight-light);
			font-size: 1.25rem;
			line-height: 1.9rem;

			@media (max-width: variables.$breakpoint-lg) {
				font-size: 1.25rem;
				line-height: 1.875rem;
			}
		}
	}

	&.variant--markLink {
		display: initial;
		padding-bottom: 0.085rem;
		min-width: 0;
		line-height: 1.5rem;
	}

	&.arrow {
		.inner::after {
			padding-left: 0.25rem;
			content: '\0203A';
		}
	}

	&.asset {
		.inner::after {
			padding-left: 0.5rem;
			content: '\f15b';
			font-weight: 200;
			/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
			font-family: 'Font Awesome 6 Pro';
		}
	}

	&.block {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		.inner {
			text-align: center;
		}
	}

	&.disabled {
		cursor: auto;
		background-color: var(--disabled-color);
	}
}
