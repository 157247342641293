@use 'src/styles/animations' as animations;
@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: flex-start;
	z-index: var(--z-index-max);
	background: var(--black-30-alpha);
	width: 100vw;
	height: 100vh;
	overflow: auto;
}

.modal {
	display: flex;
	position: relative;
	flex-direction: column;
	z-index: var(--z-index-9);
	animation: fadeIn ease 0.3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	margin: 5%;
	border-radius: var(--border-radius-1-5);
	background: var(--bg-white);
	padding: var(--space-inset-2xl);

	@media (max-width: variables.$breakpoint-md) {
		padding: var(--space-inset-2xl) var(--space-inset-md);
	}
}

.container.isCentered {
	align-items: center;
}

.icon {
	position: absolute;
	top: var(--space-stack-md);
	right: var(--space-stack-md);
	z-index: 1;
	cursor: pointer;
}
