.priceContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: var(--space-inset-md);
	background-color: var(--bg-mint-green-full);

	.linkContainer {
		margin-bottom: var(--space-stack-lg);

		.mutedText {
			margin-top: var(--space-stack-md);
		}

		a {
			display: flex;
			flex-wrap: wrap;
			align-content: center;
		}

		a .linkIcon {
			margin-left: var(--space-inline-xs);
		}
	}

	.infoContainer {
		display: flex;
		row-gap: var(--space-stack-md);
		flex-direction: column;
		width: 100%;

		.button {
			width: 100%;
		}
	}
}
