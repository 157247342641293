@use 'src/styles/variables' as variables;

.menuContent {
	display: flex;
	flex-direction: column;
	transition: height 0.4s ease-in-out;
	width: 100%;
	height: 0;
	overflow: hidden;

	&.isMenuOpen {
		height: calc(100% - 7.5rem);
		overflow-y: auto;
	}
}

.inner {
	position: relative;
	width: 90%;
	max-width: 140rem;
	height: 90%;
	max-height: 90%;

	@media (max-width: variables.$breakpoint-xl) {
		width: 96%;
	}
}

.searchWidgetWrapper,
.groupWrapper {
	display: grid;
	position: absolute;
	grid-auto-flow: column;
	inset: 0;
}

.group1,
.group2,
.group3 {
	display: inline-flex;
	flex: 1;
	justify-content: flex-end;
	border-top-right-radius: 7.4375rem;
	height: 100%;
}

.group2,
.group3 {
	margin-left: -6.5rem;
}

.group1 {
	z-index: var(--z-index-8);
	background-color: #f4faf8;
}

.group2 {
	z-index: var(--z-index-7);
	background-color: #eaf5f0;
}

.group3 {
	z-index: var(--z-index-6);
	background-color: #dcede6;
}

.linkGroup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 3rem 3rem 3rem 0;
	width: 60%;
	min-width: 15.625rem;

	@media (max-width: variables.$breakpoint-xl) {
		width: 50%;
	}

	@media (max-width: 1281px) {
		min-width: 13.25rem;
	}
}

.title {
	color: var(--text-heading-color);
	font-weight: var(--font-weight-bold);
	line-height: 2.05rem;
	font-family: var(--font-family-heavitas);
}

.divider {
	margin-top: 0.25rem;
	margin-bottom: var(--space-stack-lg);
}

.mainLinks {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	margin-bottom: var(--space-stack-md);
}

.subLinks {
	display: flex;
	flex-direction: column;
}

.space {
	width: 100%;
	height: 1rem;
}
