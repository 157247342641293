@use 'src/styles/variables' as variables;

.container {
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e6ecea;
	}

	&::-webkit-scrollbar-thumb {
		background: #007533;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #007533;
	}
}

.table {
	width: 100%;
	max-width: 62rem;

	th,
	td {
		min-width: 10rem;
		max-width: 15rem;
	}

	tr td:last-of-type {
		border-bottom: 0;
	}

	.tableHead {
		padding: var(--space-inset-sm);
		@media (min-width: variables.$breakpoint-lg) {
			border-right: none;
			border-bottom: none;
		}
	}

	.tableData {
		padding: var(--space-inset-sm);
		@media (min-width: variables.$breakpoint-lg) {
			border-right: 1px solid var(--themed-border-color);
		}
	}

	.tableData,
	.tableHead {
		@media (max-width: variables.$breakpoint-md) {
			border-right: none;
		}
	}

	.tableHead:first-of-type,
	.tableData:first-of-type {
		@media (min-width: variables.$breakpoint-xl) {
			border-bottom: none;
			text-align: left;
		}
	}

	thead th {
		border-right: none;
	}

	.tableHead:last-of-type,
	.tableData:last-of-type {
		border-right: none;
	}

	tr {
		border-bottom: 1px solid var(--themed-border-color);

		@media (max-width: variables.$breakpoint-lg) {
			margin-bottom: var(--space-stack-md);
			padding: var(--space-inset-md);
		}
	}

	tr td:first-of-type {
		@media (max-width: variables.$breakpoint-lg) {
			border-right: none;
			width: 100%;
		}
	}

	td {
		border-bottom: 1px solid var(--themed-border-color);
	}
}

/* when left column of every row is used a fieldname, enabled/disabled i storyblok */
.leftTdIsLabel {
	tr td:first-of-type {
		position: sticky;
		left: 0;
		font-weight: var(--font-weight-bold);
	}
	.tableHead td:first-of-type {
		display: none;
	}

	@media (max-width: variables.$breakpoint-xl) {
		.tableData:first-of-type {
			padding-bottom: var(--space-stack-sm);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		.tableHead tr {
			background-color: none;
		}

		.table tr .tableData:first-of-type,
		.table tr th:first-of-type {
			position: sticky;
			left: 0;
		}
	}
}

@media (max-width: variables.$breakpoint-xl) {
	.table {
		border: 0;
	}

	.outerBorder tr {
		border: 1px solid var(--themed-border-color);
	}

	.table thead {
		clip: rect(0 0 0 0);
		position: absolute;
		margin: -1px;
		border: none;
		padding: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	.table tr {
		display: block;
		margin-bottom: var(--space-stack-md);
	}

	.table tr:last-of-type {
		margin-bottom: 0;
	}

	tr .tableData:first-of-type {
		font-weight: var(--font-weight-bold);
	}

	.table td {
		display: block;
		text-align: right;
	}

	.table td::before {
		float: left;
		padding-right: var(--space-inset-xs);
		content: attr(data-label);
		font-weight: var(--font-weight-bold);
	}

	.emptyTd.emptyTd.emptyTd {
		display: none;
	}
}

/* RESPONSIVE */

@media (max-width: variables.$breakpoint-xl) {
	.table {
		display: inline-table;
		padding: var(--space-stack-md);
		max-width: 100%;

		th,
		td {
			min-width: 10rem;
			max-width: 100%;
			text-align: right;
		}
	}
}

/* Style exceptions*/
.educationEventTable,
.educationSessionTable {
	display: inline-table;
	overflow-x: hidden;

	.tableData {
		border-right: 0;
		.row {
			text-align: right;
		}
	}

	tr {
		th,
		td {
			background: transparent !important;
		}
	}
}

.educationEventTable {
	&.table {
		.tableData:last-of-type {
			text-align: right;
		}
	}
}

.educationSessionTable.educationSessionTable.educationSessionTable {
	tr th {
		border-bottom: 1px solid var(--line-color);
	}

	&.table {
		td:first-of-type::before {
			content: '';
		}

		th:first-of-type {
			text-align: right;
		}
	}

	tr:last-of-type {
		border: none;
	}

	@media (max-width: variables.$breakpoint-xl) {
		.tableData:first-of-type {
			border-bottom: 1px solid var(--line-color);
		}
	}
}
