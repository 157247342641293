@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	align-self: center;
	gap: 0.5rem;
	margin: 1rem 0;
	width: 100%;
	max-width: 48.75rem;

	@media (max-width: variables.$menu-bp) {
		margin-bottom: 2rem;
	}
}

.searchFieldWrapper {
	position: relative;
	flex: 1;
	z-index: calc(var(--z-index-max) + 2);
	height: 2.5rem;

	@media (max-width: variables.$menu-bp) {
		height: 3.5rem;
	}
}

.textField {
	position: absolute;
	z-index: calc(var(--z-index-max) + 2);
	width: 100%;
}

.btn.btn.btn {
	height: 100%;
}

.desktop {
	display: flex;
	@media (max-width: variables.$menu-bp) {
		display: none;
	}
}

.suggestions {
	display: flex;
	position: absolute;
	top: 1.25rem;
	flex-direction: column;
	gap: 0.25rem;
	z-index: calc(var(--z-index-max) + 1);
	border-bottom-right-radius: var(--border-radius-2);
	border-bottom-left-radius: var(--border-radius-2);
	background-color: var(--bg-white);
	padding: var(--space-inset-2xl) var(--space-inset-lg) var(--space-inset-lg);
	width: 100%;
}
