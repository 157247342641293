@use 'src/styles/variables' as variables;

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: var(--space-stack-xl);
	color: var(--text-heading-color);

	@media (min-width: variables.$breakpoint-lg) {
		&.firstCard {
			border-radius: var(--border-radius-card) 0 0 var(--border-radius-card);
		}

		&.lastCard {
			border-radius: 0 var(--border-radius-card) var(--border-radius-card) 0;
		}
	}

	@media (max-width: variables.$breakpoint-lg) {
		&.firstCard {
			border-radius: var(--border-radius-card) var(--border-radius-card) 0 0;
		}

		&.lastCard {
			border-radius: 0 0 var(--border-radius-card) var(--border-radius-card);
		}
	}
}

.cisionFormContainer,
.upsalesFormContainer {
	min-height: 396px;
}
.content {
	margin-bottom: var(--space-stack-xl);
}

.footer {
	display: flex;
	justify-content: space-between;
}

.checkboxes {
	margin-bottom: var(--space-stack-xl);
}

.feedbackContainer {
	height: 100%;

	.headingContainer {
		display: flex;
		align-content: center;
		margin-bottom: var(--space-stack-xs);

		.feedbackTitle {
			margin: 0;
			line-height: 2;
		}
	}
	.icon {
		margin-right: 0.5rem;
	}
}

.error {
	margin-bottom: var(--space-stack-lg);
	color: var(--red-400);
}

.cisionForm {
	.fieldset {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		padding-bottom: var(--space-stack-lg);
	}

	.option {
		margin-bottom: var(--space-stack-xs);
	}

	.textField {
		width: 100%;
	}
}

.theme--yellow {
	background-color: var(--custom-yellow-light);
}

.theme--dark-yellow {
	background-color: var(--primary-yellow-25);
}

.theme--white {
	padding: 0;
}
