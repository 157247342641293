@use 'src/styles/functions' as functions;

.inputContainer input::-webkit-outer-spin-button,
.inputContainer input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.inputContainer input[type='number'] {
	-moz-appearance: textfield;
}

.container {
	display: flex;
	align-items: center;
}

.container.disabled .input {
	cursor: not-allowed;
	border-color: var(--disabled-color);
	color: var(--disabled-color);
}

.container.invalid .input {
	border-color: var(--danger-color);
}

.container.invalid .icon {
	color: var(--danger-color);
}

/* INPUT */
.inputContainer {
	padding: 0 var(--space-inline-sm);
}

.input {
	transition:
		all 0.15s ease-in-out,
		border 0.3s ease-in-out;
	outline: none;
	border: 1px solid var(--action-color);
	border-radius: var(--border-radius-input);
	width: functions.rem(60px);
	height: functions.rem(40px);
	text-align: center;
}

.input:hover {
	border-color: var(--border-hovered-color);
}

.input:focus {
	border-color: var(--border-hovered-color);
}

.input.small {
	height: functions.rem(24px);
}

.input.large {
	height: functions.rem(56px);
}

/* ICON */
.icon {
	color: var(--action-color);
	&:hover {
		cursor: pointer;
	}
}

.icon.disabled {
	cursor: not-allowed;
	color: var(--disabled-color);
}
