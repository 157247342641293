@use 'src/styles/variables' as variables;

.container {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.withOverlay {
		background-color: var(--bg-dark-green-full);
	}

	&.borderRadius {
		@media (min-width: variables.$breakpoint-lg) {
			border-radius: var(--border-radius-2);

			.imageContainer img,
			.overlay {
				border-radius: var(--border-radius-2);
			}
		}
	}
}

.imageContainer {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;

	&.direction--full {
		.overlay {
			background: linear-gradient(0deg, rgba(50, 50, 50, 0.6) 21.68%, rgba(50, 50, 50, 0.6) 47.4%);
		}
	}

	&.direction--to-right {
		.overlay {
			background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 80%);
		}
	}

	&.direction--to-left {
		.overlay {
			background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 80%);
		}
	}

	&.direction--center {
		.overlay {
			background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
		}
	}
}

.blurOverlay {
	position: absolute;
	backdrop-filter: blur(3px);
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.overlay {
	position: absolute;
	inset: 0;
}

@media (max-width: variables.$breakpoint-lg) {
	.container:has(.layout-sm--top),
	.isHeader {
		position: relative;
		min-height: var(--image-min-height-md);

		.overlay {
			display: none;
		}
	}

	.menuVersionV3 {
		.isHeader {
			min-height: var(--image-height);
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.container {
		position: initial;
	}

	.isHeader {
		position: relative;
		min-height: var(--image-min-height-md);
	}

	.menuVersionV3 {
		.isHeader {
			min-height: var(--image-height);
		}
	}
}
