.salaryBreakDown {
	padding: 0;
	list-style-type: none;
}

.salaryAfterTaxLabel {
	margin: 0;
}

.salaryBreakDownRow {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: var(--space-stack-xs);
	border-radius: var(--border-radius-025);
	padding: var(--space-inset-xs) var(--space-inset-md);
}

.pieChart {
	margin: 0 auto;
	width: 11rem !important;
	height: 11rem !important;
}
