@use 'src/styles/variables' as variables;
@use 'src/styles/animations' as animations;

.header {
	margin-bottom: var(--space-stack-2xl);
}

.brandline {
	height: 0.25rem;
}

.linkGroup {
	padding-left: var(--space-inset-md);
	list-style: none;
}

.animation {
	opacity: 0;
	transition:
		opacity 0.2s linear,
		max-height 0.3s ease-out;
	max-height: 0;
	overflow: hidden;

	&.open {
		opacity: 1;
		transition:
			opacity 0.2s linear,
			max-height 0.5s ease-in;
		max-height: 60rem;
	}
}

.firstLevel {
	padding-left: 0;
}

.secondLevel li,
.thirdLevel li {
	padding: 0.25rem 0;
}

.linkWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.linkItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid var(--line-color);
	border-left: 4px solid var(--line-color);
	padding: var(--space-inset-sm);

	&.activeGroup {
		@media (min-width: variables.$breakpoint-lg) {
			border-left: 4px solid var(--primary-yellow);
		}
	}
}

.icon {
	svg {
		height: 1rem;
	}
}

.link {
	a {
		font-weight: var(--font-weight-light);
	}
}

.activeLink {
	a {
		font-weight: var(--font-weight-bold);
	}
}

.hamburgerIcon {
	display: none;
}

@media (max-width: variables.$breakpoint-lg) {
	.title {
		opacity: 0;
		transition: none;
		width: 0;
		height: 0;
		white-space: nowrap;
	}

	.links {
		opacity: 0;
		transition:
			opacity 0.2s ease-in-out 0s,
			max-height var(--animation-duration) ease-in-out 0s,
			width var(--animation-duration) ease-in-out 0.4s;
		background-color: var(--bg-white);
		width: 0;
		max-height: 0;
		overflow: hidden;
	}

	.linkWrapper,
	.link a {
		white-space: nowrap;
	}

	.container {
		z-index: var(--z-index-10);
		--animation-duration: 0.6s;
		--top-initial: calc(var(--global-menu-height) + var(--global-menu-offset) * 2 + 1.5rem);
		--border-radius: 2.3rem;
		--border-radius-inital: 0 var(--border-radius) var(--border-radius) 0;
		--initial-menu-width: 5.25rem;
		--expanded-menu-width: calc(100vw - var(--global-menu-offset) * 2);

		top: var(--top-initial);
		transition: none;
		border-radius: var(--border-radius-inital);
		background-color: var(--bg-white-90);
		padding: var(--space-inset-xs) var(--space-inset-md) var(--space-inset-xs) 2.5rem;
		width: var(--initial-menu-width);
		height: auto;

		&.oldMenu {
			--global-menu-offset: 1rem;
			--top-initial: calc(var(--global-menu-height) + 3rem);

			&.hideGlobalMenu {
				--global-menu-offset: 0;
				transition: var(--animation-duration) border-radius ease;
				transition-delay: 0.2s;
				border-radius: 0;
			}
		}

		&.hideGlobalMenu,
		&.isMenuOpen {
			right: var(--global-menu-offset);
			left: var(--global-menu-offset);
			border-radius: var(--border-radius);
			background-color: var(--bg-white);
			padding: var(--space-inset-lg);
			width: var(--expanded-menu-width);

			.title {
				opacity: 1;
				transition:
					opacity var(--animation-duration) ease-in-out 0.1s,
					width var(--animation-duration) ease-in-out 1s;
				width: auto;
				height: auto;
			}
		}

		&.hideGlobalMenu {
			top: var(--global-menu-offset);
			animation: expandVertically var(--animation-duration) ease-in-out;
			height: var(--global-menu-height);
		}

		&.isMenuOpen {
			border-radius: var(--border-radius);
			height: auto;

			.header {
				margin-bottom: var(--space-stack-md);
			}

			.links {
				opacity: 1;
				transition:
					opacity 0.2s ease-in-out 0s,
					max-height var(--animation-duration) ease-in-out 0s,
					width var(--animation-duration) ease-in-out 0.4s;
				width: auto;
				max-height: 70svh;
				overflow-y: auto;
			}
		}

		&.slideUp {
			top: var(--global-menu-offset);
			transition: top 0.3s ease;
		}

		&.slideDown {
			transition: top 0.3s ease;
		}

		&.expandAndOpen {
			animation: expandAndOpen var(--animation-duration) ease-in-out;
		}

		&.minimize {
			animation: minimize var(--animation-duration) ease-in-out;
		}

		&.zIndex {
			z-index: var(--z-index-7);
		}
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		padding: 0;
	}

	.firstLevel {
		opacity: 0;
		transition:
			opacity 0.2s linear,
			max-height 0.5s ease-out;
		margin-bottom: 0;
		max-height: 0;

		&.isOpen {
			display: inline-block;
			opacity: 1;
			transition:
				opacity 0.2s linear,
				max-height 0.5s ease-in;
			width: 100%;
			max-height: calc(100vh - 8rem);
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background-color: #e6ecea;
			}
		}
	}

	.linkItem {
		border-left: 4px solid transparent;
	}

	.linkItem:last-of-type {
		border-bottom: none;
	}

	.hamburgerIcon {
		display: block;
	}

	.brandline {
		display: none;
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		--border-radius: 1.7rem;

		&.hideGlobalMenu,
		&.isMenuOpen {
			padding: var(--space-inset-md);
		}
	}
}

@media (min-width: variables.$breakpoint-lg) {
	.container {
		display: inline-block;
		width: 100%;
		max-height: calc(100vh - 10rem);
		overflow-y: auto;
		scrollbar-color: var(--text-yellow) #e6ecea;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: #e6ecea;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--text-yellow);
		}

		&::-webkit-scrollbar-thumb:hover {
			background: var(--text-yellow);
		}
	}

	.firstLevel {
		margin-right: 0.75rem;
	}
}

@keyframes expandVertically {
	0% {
		top: var(--top-initial);
		width: var(--initial-menu-width);
		height: auto;
	}

	30% {
		height: var(--global-menu-height);
	}

	100% {
		top: var(--global-menu-offset);
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}
}

@keyframes expandAndOpen {
	0% {
		width: var(--initial-menu-width);
	}

	100% {
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}
}

@keyframes minimize {
	0% {
		right: var(--global-menu-offset);
		left: var(--global-menu-offset);
		width: var(--expanded-menu-width);
	}

	5% {
		right: auto;
		left: 0;
	}

	100% {
		top: var(--top-initial);
		width: var(--initial-menu-width);
	}
}
