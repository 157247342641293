.loanContainer {
	z-index: 0;
	margin: 0 auto;
	border-radius: var(--border-radius-input);
	padding: var(--space-inset-xl);
	width: 38.5rem;
	max-width: 100%;

	.title {
		text-align: center;
	}

	.slider {
		margin-bottom: var(--space-stack-2xl);
	}

	.linkButton {
		margin-bottom: var(--space-stack-xl);
	}
	.footerText {
		text-align: center;
	}
}
