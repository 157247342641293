.showMoreButton {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: var(--z-index-1);
	margin: 0 auto;
	border: none;
	background: none;
	padding-top: var(--space-stack-3xl);
	font-weight: var(--font-weight-bold);

	span {
		margin-bottom: 0.3rem;
	}
	.showMoreIcon {
		max-width: 1.5rem;
	}
}
