@use 'src/styles/variables' as variables;

// Common
.common {
	scroll-margin-top: 6rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.3;
}

.heavitas {
	font-weight: var(--font-weight-heavitas-extra-bold);
	text-transform: lowercase;

	&.size--h1,
	&.size--h2,
	&.size--h3 {
		font-family: var(--font-family-heavitas);
	}

	&.size--h1 {
		font-size: var(--heavitas-font-size-h1);
		line-height: clamp(3rem, 3.5vw, 4.5rem);
	}

	&.size--h2 {
		font-size: var(--heavitas-font-size-h2);
		line-height: clamp(2.5rem, 3.5vw, 4rem);
	}

	&.size--h3 {
		font-size: var(--heavitas-font-size-h3);
		line-height: 2.1rem;
		letter-spacing: 0;

		@media (max-width: variables.$breakpoint-xl) {
			line-height: 2.25rem;
		}
	}
}

.titleWithLongWord {
	&.size--h1,
	&.size--h2 {
		@media (max-width: variables.$breakpoint-sm) {
			--heavitas-font-size-h1: 1.4rem;
			--heavitas-font-size-h2: 1.4rem;
			line-height: 1.9;
		}
	}
}

// Margin bottoms
.size--h1,
.size--h2,
.size--h3,
.size--h4,
.size--h5,
.size--h6 {
	&.margin-bottom--none {
		margin-bottom: 0;
	}

	&.margin-bottom--2xs {
		margin-bottom: var(--space-stack-xs);
	}

	&.margin-bottom--xs {
		margin-bottom: var(--space-stack-xs);
	}

	&.margin-bottom--sm {
		margin-bottom: var(--space-stack-sm);
	}

	&.margin-bottom--md {
		margin-bottom: var(--space-stack-md);
	}

	&.margin-bottom--lg {
		margin-bottom: var(--space-stack-lg);
	}

	&.margin-bottom--xl {
		margin-bottom: var(--space-stack-xl);
	}

	&.margin-bottom--2xl {
		margin-bottom: var(--space-stack-2xl);
	}

	&.margin-bottom--3xl {
		margin-bottom: var(--space-stack-3xl);
	}
}

// Size

.size--h1 {
	font-size: var(--font-size-h1);
	line-height: 1.2;
}

.size--h2 {
	font-size: var(--font-size-h2);
}

.size--h3 {
	font-size: var(--font-size-h3);
}

.size--h4 {
	font-size: var(--font-size-h4);
}

.size--h5 {
	font-size: var(--font-size-h5);
}

.size--h6 {
	font-size: var(--font-size-h6);
}

.size--h1,
.size--h2,
.size--h3 {
	letter-spacing: -0.5px;
}
