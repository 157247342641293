@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

$circle-size-lg: functions.rem(75px);
$circle-size-sm: functions.rem(50px);

.stepContainer {
	display: flex;
	flex-flow: column wrap;
	height: 100%;

	.stepIndex {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -0.8625rem;
		border-radius: var(--border-radius-round);
		background: var(--accent-pink);
		min-width: $circle-size-lg;
		max-width: $circle-size-lg;
		min-height: $circle-size-lg;
		max-height: $circle-size-lg;
		color: var(--text-dark);
		font-weight: var(--font-weight-bold);
		font-size: functions.rem(50px);
	}

	.textContainer {
		align-self: flex-start;
		margin-top: var(--space-stack-xl);
	}

	.text {
		max-width: 60%;
	}

	.textColumn {
		display: flex;
	}

	&.layoutVertical {
		flex-flow: row nowrap;

		.stepIndex {
			margin-top: -0.5rem;
			margin-right: var(--space-inline-xl);
			margin-left: 0;
		}

		.textContainer {
			margin-top: calc(#{$circle-size-sm}/ 2 - 0.85rem);
		}
	}
}

.gridContainer {
	margin-bottom: var(--space-inset-3xl);
}

.additionalText {
	max-width: 56.25rem;
}

@media (max-width: variables.$breakpoint-xl) {
	.stepContainer {
		.stepIndex {
			margin-right: var(--space-inline-md);
			margin-left: 0;
			min-width: $circle-size-sm;
			max-width: $circle-size-sm;
			min-height: $circle-size-sm;
			max-height: $circle-size-sm;
			font-size: functions.rem(32px);
		}
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.stepContainer {
		.textColumn {
			flex-direction: column;
		}

		.text {
			max-width: 100%;
		}
	}
}

@media (max-width: variables.$breakpoint-md) {
	.stepContainer {
		&.withBlok {
			flex-direction: column;
		}

		.stepIndex {
			margin-right: var(--space-inline-md);
			margin-left: 0;
			min-width: $circle-size-sm;
			max-width: $circle-size-sm;
			min-height: $circle-size-sm;
			max-height: $circle-size-sm;
			font-size: functions.rem(32px);
		}

		.textContainer {
			margin-top: calc(#{$circle-size-sm}/ 2 - 0.85rem);
		}

		&.layoutVertical {
			.stepIndex {
				min-width: $circle-size-sm;
				min-height: $circle-size-sm;
				max-height: $circle-size-sm;
			}
		}
	}
}
