.integratorInformation {
	display: flex;
	flex-direction: column;
	gap: var(--space-stack-xs);

	border: 1px solid var(--border-color);
	border-radius: var(--border-radius-1);
	overflow: hidden;
}

.logo {
	padding: var(--space-inset-lg);
}

.info {
	background-color: var(--bg-dark-green-extra-light);
	padding: var(--space-inset-lg);
}

.integratorLogo {
	display: flex;
	align-items: center;
	width: 100%;
	height: 10.625rem;
	max-height: 10.625rem;
	object-fit: contain;
}

.integratorHeader {
	padding-bottom: var(--space-inset-xl) !important;
}
.contactInformation {
	background-color: transparent;
	padding: 0;
}
