.titleContainer {
	margin-bottom: var(--space-stack-xl);

	.title {
		font-weight: var(--font-weight-light);
	}
}

.grid {
	margin-bottom: var(--space-stack-xl);
}

.linkContainer {
	display: flex;
	justify-content: flex-end;
}
