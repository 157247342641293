@use 'src/styles/variables' as variables;

.container {
	z-index: 1;
	font-weight: var(--font-weight-heavitas-extra-bold);
	font-size: clamp(2.5rem, 5vw, 4.5rem); // 67px
	line-height: 1.3;
	font-family: var(--font-family-heavitas);
	font-feature-settings:
		'clig' off,
		'liga' off;
	text-align: center;
	text-transform: lowercase;

	@media (min-width: variables.$breakpoint-max) {
		font-size: 5.625rem;
	}

	&.longText {
		@media (min-width: variables.$breakpoint-md) {
			font-size: 4.125rem;
		}
	}

	&.longText,
	&.longWord {
		@media (max-width: variables.$breakpoint-md) {
			font-size: 1.7rem;
		}

		@media (max-width: variables.$breakpoint-sm) {
			font-size: 1.35rem;
		}
	}

	&.quotation::before {
		content: '\201C';
	}

	&.quotation::after {
		content: '\201D';
	}
}
