.sectionHeader {
	padding-bottom: 0 !important;
}

.textFieldContainer {
	padding-bottom: 0.75rem;
}

.radioButtonContainer.radioButtonContainer {
	padding-bottom: 0.75rem;
}

.formSection {
	padding-bottom: 2rem;
}

.textSection {
	padding-bottom: 2rem;
}

.dietCheckbox {
	padding-right: var(--space-inline-md);
}

.orgNrContainer {
	display: flex;
}

.companyInfoBtnContainer {
	align-self: baseline;
	margin-top: 0.75rem;
	margin-left: 2rem;
}

.companyInfoBtn {
	border: none;
	background: none;
	padding: 0;

	&:focus {
		background: none;
	}

	&:disabled {
		background: none;
		color: var(--disabled-color);
	}
}

.companyInformationErrorMsg {
	--bodycolor: var(--red-400);
}

.orgNrField {
	width: 50%;
}

.integrityMsgBtn {
	padding-right: 0;
	font-size: 0.875rem;
	line-height: 1.3125rem;
}

.campaignCodeValidationOk {
	display: flex;
	column-gap: var(--space-inline-xs);
	align-items: center;
	margin-top: -0.5rem;
	min-height: var(--space-stack-2xl);
}

.campaignCodeValidationError {
	display: flex;
	column-gap: var(--space-inline-xs);
	align-items: center;
	margin-top: -0.5rem;
	min-height: var(--space-stack-2xl);
	color: var(--danger-color);
}

@media (max-width: 36rem) {
	.orgNrContainer {
		flex-direction: column;
	}

	.orgNrField {
		padding-bottom: 0;
		width: 100%;
	}

	.companyInfoBtnContainer {
		margin: 0;
		margin-bottom: var(--space-stack-md);
	}

	.companyInformationErrorMsg {
		margin-bottom: initial;
	}
}
